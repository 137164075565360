@import '../../styles/Reset';

@mixin register-slideshow {
  width: $size-592;
  max-width: 48vw;
}

@keyframes first-form {
  from {
    left: (-$size-360);
    opacity: 0;
    visibility: hidden;
    transform: scaleX(0.2);
  }

  to {
    left: 0;
    opacity: 1;
    visibility: visible;
    transform: scaleX(1);
  }
}

@keyframes first-form-inactive {
  from {
    left: 0;
    opacity: 1;
    visibility: visible;
    transform: scaleX(1);
  }

  to {
    left: (-$size-360);
    opacity: 0;
    visibility: hidden;
    transform: scaleX(0.2);
  }
}

@keyframes second-form {
  from {
    left: $size-360;
    opacity: 0;
    visibility: hidden;
    transform: scaleX(0.2);
  }

  to {
    left: 0;
    opacity: 1;
    visibility: visible;
    transform: scaleX(1);
  }
}

@keyframes second-form-inactive {
  from {
    left: 0;
    opacity: 1;
    visibility: visible;
    transform: scaleX(1);
  }

  to {
    left: $size-360;
    opacity: 0;
    visibility: hidden;
    transform: scaleX(0.2);
  }
}

.register-page {
  display: flex;
  height: 100vh;
  min-height: $size-840;

  @media (max-height: $size-638) and (max-width: $mobile-breakpoint-width) {
    padding-top: $size-34;
  }

  .register-form--wrap,
  .register-ad--wrap {
    position: relative;
    justify-content: center;
    width: 100%;
    text-align: center;

    display: flex;
    flex-direction: column;

    min-height: $size-840;
  }

  .register-ad--wrap {
    display: none;
    background-color: #F9FAFB;
    color: #231975;
  }

  .register-form__line {
    width: $size-360;
  }

  .register-ad-line--wrap {
    @include register-slideshow;
  }

  .register-ad-slide>div {
    @include register-slideshow;
  }

  .register-ad-line__subtitle--wrap {
    margin: 0;
  }

  .google-btn {
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $size-12;
    }
  }

  @media (min-width: $register-ad-breakpoint-width) {

    .register-form--wrap,
    .register-ad--wrap {
      width: 50%;
    }

    .register-ad--wrap {
      display: inherit;
    }

    .register__footer {
      display: none;
    }
  }

  .feedback-avatar {
    width: $size-64;
    height: $size-64;
    border-radius: 50%;
  }

  .feedback-stars--wrap svg {
    margin: 0 $size-2;
    width: $size-20;
    height: $size-20;
  }

  .buttons-wrapper {
    flex-direction: column;
    gap: $size-16;

    @media (min-width: $mobile-breakpoint-width) {
      flex-direction: row;
    }
  }

  .terms-conditions-text p {
    color: $input-warning-bg-color;
  }

  .checkbox-error {
    svg {
      rect {
        stroke: transparent;
      }
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: row;
    width: $size-360;
    min-height: $size-525;
    overflow: hidden;

    .first-form {
      position: absolute;
      left: 0;
      visibility: visible;
      transform: scaleX(1);

      &.active {
        animation: first-form .3s forwards;
      }

      &.inactive {
        animation: first-form-inactive .3s forwards;
        display: none;
      }
    }

    .second-form {
      position: absolute;
      left: $size-360;
      visibility: hidden;
      transform: scaleX(0.2);

      &.active {
        animation: second-form .3s forwards;
      }

      &.inactive {
        animation: second-form-inactive .3s forwards;
        display: none;
        scale: 0;
      }
    }
  }
}

.not-logged-page {
  .doo-score-word {
    color: $not-logged-dark-color !important;
  }
}