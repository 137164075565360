@import '../../../../styles/Reset';

$payment-type-border-color: #D0D5DD;
$active-button-bg-color: #EAECF0;
$checkbox-text-color: #344054;

.payment-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .button-container {
    display: flex;
    flex-direction: row;
    gap: $size-10;
    margin-bottom: $size-22;

    .payment-type-btn {
      background-color: transparent;
      border: $size-1 solid $payment-type-border-color;
      width: $size-56;
      height: $size-40;
      border-radius: $size-6;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        background-color: $active-button-bg-color;
      }
    }
  }

  .card-types {
    display: flex;
    flex-direction: row;
    margin-bottom: $size-24;

    .card-type {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $size-56;
      height: $size-40;
      opacity: 0.3;

      &.active {
        opacity: 1;
      }
    }
  }

  .form-inputs {
    display: flex;
    flex-direction: column;
    gap: $size-11;
    overflow-y: auto;
    padding-left: $size-1;
    padding-bottom: $size-1;

    .form-row {
      display: flex;
      flex-direction: row;
      gap: $size-19;
      padding-bottom: $size-1;
      padding-left: $size-1;
    }

    .expiry-date,
    .cvc {
      .input {
        text-align: center;
      }
    }

    .error-messages {
      margin-top: (-$size-11);

      p {
        color: $input-error-color;
      }

      p:first-child {
        margin-top: $size-8;
      }
    }

    .MuiFormControlLabel-root {
      margin-left: (-$size-8);
    }

    .bank-name {
      p:first-child {
        color: $not-logged-light-color;
      }
    }
  }

  .giropay-description {
    margin-bottom: $size-24;
  }

  .save-card-checkbox {
    p {
      color: $checkbox-text-color;
    }

    svg {
      @media (min-width: $hd-breakpoint-width) {
        width: $size-17;
        height: $size-18;
      }
    }
  }

  .transfer-desc-row {
    margin-bottom: $size-16;
  }

}