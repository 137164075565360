@import '../../../../styles/Reset';

.section-retouch {

  .section-content-column {
    width: 50% !important;
  }

  .remark-modal-value {
    padding-left: $size-36;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    p {
      font-size: $size-12;
    }

    .remark-modal-value-edit {
      margin: 0 $size-20;
      background-color: transparent;

      &[disabled] {
        cursor: default;
        opacity: 0.5;
      }

      svg {
        width: $size-15;
        height: $size-15;
      }
    }
  }
}