@import '../../../../styles/Reset';

.orderTable {
  .orderTable-timing {

    &.timer {
      display: flex;
      align-items: center;
      gap: $size-6;

      svg path {
        fill: $light-color;
      }
    }

    .timing-orange {
      color: $orange-500-color;
    }
  }

  .orderTable-timing-text {
    color: $light-grey-color;
  }
}

.table-row.open {
  .orderTable-timing {

    &.timer {

      svg path {
        fill: $white-color;
      }
    }
  }
}