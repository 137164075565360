@import '../../../../styles/Reset';

.new-order-page {

  .overview-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .overview-section {
      border: $size-1 solid $table-row-border-color;
      padding: $size-21;
      margin-bottom: $size-100;

      .overview-section-top {
        color: $dark-color;
        display: grid;
        gap: $size-8;
        margin-bottom: $size-40;
      }

      .overview-table {
        margin-left: auto;
        max-width: $size-700;

        .format-price {
          .format-price-row {
            display: flex;
            justify-content: space-between;
            padding: $size-12 $size-14 $size-12 $size-28;
            color: $dark-color;
            border: $size-1 solid $input-border-color;
            border-top: none;

            &.bgFill {
              background-color: $switch-active-color;
              color: $white-color;
            }
          }
        }

        .table-price-row {
          display: flex;

          .row-description {
            margin-left: auto;
            padding: $size-12;

            .remove-coupon {
              background-color: transparent;
              margin-left: $size-10;
              color: $switch-active-color;
              font-family: $font-semibold;
              font-size: $size-14;
            }
          }

          .row-price {
            width: $size-115;
            white-space: nowrap;
            padding: $size-12 $size-18;
            display: flex;
            justify-content: flex-end;

            &.bottom-line {
              border-bottom: $size-1 solid $input-border-color;
            }
          }

          &.orange {
            p {
              color: $orange-500-color;
            }
          }
        }

        .overview-table-bottom {
          color: $dark-color;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-bottom: $size-42;

          .table-bottom-couponText {
            padding: $size-18 $size-18 $size-20;
            background-color: transparent;

            p {
              color: $dark-color;
              border-bottom: $size-1 solid $dark-color;
            }
          }

          .add-coupon {
            display: flex;
            gap: $size-5;
            margin: $size-18;

            .input--wrap {
              width: auto;

              input {
                max-width: $size-120;
              }
            }

            button {
              padding: 0 $size-20;
              width: auto;
            }
          }

          .coupon-success-text,
          .coupon-error-text {
            padding: $size-4 $size-12;
            margin: $size-18;
            background-color: $success-600-color;
            color: $white-color;
            display: flex;
            gap: $size-4;
            border-radius: $size-5;

            button {
              background-color: transparent;
              color: $white-color;
              text-decoration: underline;
              font-family: $font-medium;
            }
          }

          .coupon-error-text {
            background-color: $input-error-color;
          }

          .table-bottom-check {
            padding: 0 $size-18 $size-13;
            max-width: $size-400;

            label {
              display: flex;
              align-items: flex-start;

              .MuiCheckbox-root {
                padding: $size-3;
                margin-right: $size-6;
              }

              p {
                a {
                  color: $not-logged-light-color;
                  text-decoration: none;
                  border-bottom: $size-1 solid $not-logged-light-color;
                }
              }
            }
          }

          .table-bottom-button {
            display: flex;
            justify-content: flex-end;

            button {
              padding-left: $size-15;
              padding-right: $size-15;
              line-height: $size-20;
            }
          }
        }
      }

      .overview-bottom {
        color: $dark-color;

        .overview-bottom-title {
          margin-bottom: $size-8;
        }

        .overview-bottom-description {
          display: grid;
          gap: $size-14;
        }

      }
    }

    .price-widget {
      display: flex;
      gap: $size-4;
      background-color: $dark-color;
      color: $white-color;
      padding: $size-5 $size-26;
      border-radius: $size-8 $size-8 0 0;
      position: fixed;
      z-index: 3;
      bottom: 0;

      &.right {
        left: auto;
        right: $size-24;
        transform: translateX(0%);
      }
    }

    .critical-group {
      margin: $size-24 0;

      .critical-group-content {
        list-style: none;
        margin-top: $size-8;

        li {
          margin-bottom: 0;

          p:before {
            content: '\00B7';
            padding-right: $size-6;
            font-size: $size-20;
            vertical-align: top;
          }
        }
      }
    }

    .critical-bottom-text {
      margin-bottom: $size-24;
    }
  }
}