@import '../../../../styles/Reset';

$support-tab-inactive-color: #D0D5DD;
$reset-text-color: #344054;

.modal--wrap {
  .modal.modal-large {
    min-height: auto;

    .public-user-modal {
      padding: $size-6;
      min-height: $size-566;
      display: flex;
      flex-direction: column;

      .user-form__tab {
        cursor: pointer;
        border-bottom: $size-1 solid $support-tab-inactive-color;
        padding-right: $size-12;
        padding-bottom: $size-15;
        transition: border-bottom-color .3s ease-in-out;
      }

      .user-form__tab:first-of-type {
        padding-right: $size-18;
      }

      .user-form__tab--active {
        border-bottom: $size-1 solid $dark-color;
      }

      .profile-form {
        margin-top: $size-20;
        margin-bottom: $size-10;
        height: $size-250;

        .title-firstname-lastname {
          margin-bottom: $size-20;
        }
      }

      .modal-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: $size-32;

        button {
          width: auto;
          line-height: $size-13;
        }
      }

      .gallery-management-wrapper {
        margin-bottom: $size-10;
        height: $size-270;
      }
    }
  }
}