@import '../../styles/Reset';

$support-image-width: $size-575;
$support-image-height: $size-630;

$support-form-width: $size-538;
$support-form-gap: $size-40;

$support-tab-inactive-color: #D0D5DD;

$support-phone-bg-color: #F8F8F8;

.support-page {
  margin: 0 auto;
  padding-top: $size-39;

  .support-form {
    display: flex;
    column-gap: $support-form-gap;
  }

  .support-form__image {
    display: none;
  }

  .support-form__form {
    width: 100%;
    margin: 0 auto;
  }

  .support-form__tab {
    cursor: pointer;
    border-bottom: $size-1 solid $support-tab-inactive-color;
    padding-right: $size-12;
    padding-bottom: $size-10;
    transition: border-bottom-color .3s ease-in-out;
  }

  .support-form__tab:first-of-type {
    padding-right: $size-30;
  }

  .support-form__tab--active {
    border-bottom: $size-1 solid $dark-color;
  }

  .support-form--upload--wrap {
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-left: $size-30;
    max-width: 100%;

    p:hover {
      border-bottom: $size-1 solid $light-color;
    }

    svg {
      position: absolute;
      top: $size-3;
      left: 0;
    }
  }

  .support-form--upload--wrap__delete {
    svg {
      top: $size-2;
      left: 0;
      height: $size-22;
      width: $size-22;
      border-radius: $size-3;
      padding: $size-4;
    }

    svg:hover {
      background-color: $hover-bg-color;
    }
  }

  .support-form--phone--wrap {
    background-color: $support-phone-bg-color;
    border-radius: $size-8;
    padding: $size-24 $size-24;
  }

  .support-faq {
    display: none;
    max-width: $size-768;
    margin: $size-120 auto;
  }

  .support-faq--line {
    padding: $size-24 0 $size-18 0;
    border-bottom: $size-1 solid #EAECF0;
  }

  .support-faq--line:last-of-type {
    border-bottom: none;
  }

  .support-faq--question {
    cursor: pointer;
    position: relative;
    padding-right: $size-50;

    svg {
      position: absolute;
      top: $size-3;
      right: 0;
    }
  }

  .support-faq--answer {
    overflow: hidden;
    height: 0;
    transition: height .3s ease-out;

    div {
      display: flex;
      padding-right: $size-50;
      padding-bottom: $size-13;
    }

    div:last-of-type {
      padding-bottom: 0;
    }

    a {
      color: $light-color;
      border-bottom: $size-1 solid $light-color;
      text-decoration: none;
    }

    a:hover {
      color: $light-color;
    }
  }

  @media (min-width: $tablet-breakpoint-width) {
    .support-faq {
      display: inherit;
    }

    .support-faq--tablet {
      display: none;
    }

    max-width: calc(#{$support-image-width} + #{$support-form-width} + #{$support-form-gap});

    .support-form__form {
      width: $support-form-width;
      min-width: $support-form-width;
    }
  }

  @media (min-width: $support-image-breakpoint-width) {
    .support-form__image {
      display: inherit;
      width: 100%;
      max-width: $support-image-width;
      height: $support-image-height;
      max-height: $support-image-height;
      background: url("../../../public/image/support_cover.png") center center/cover no-repeat;
      border-radius: $size-8;
    }
  }
}
