@import '../../styles/Reset';

$progress-bar-bg-color: #c4c4c4;
$order-status-active: #231975;

@keyframes shakeX {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  20%,
  60% {
    transform: translate3d((-$size-5), 0, 0);
  }

  40%,
  80% {
    transform: translate3d($size-5, 0, 0);
  }
}

@keyframes slideInLeft100 {
  from {
    left: -100%;
  }

  to {
    left: 0;
  }
}

@keyframes slideInLeft50 {
  from {
    left: -50%;
  }

  to {
    left: 0;
  }
}

svg.animate__shakeX {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: shakeX;
}

.order-success-page {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .page-icon-container {
    margin-top: $size-12;
    margin-bottom: $size-13;

    .loader-icon {
      margin: $size-25 auto;
    }

    .lottie-animation {
      width: $size-171;
      height: $size-171;
      margin: 0 auto;
    }
  }

  .title-container {
    height: $size-32;
    margin-bottom: $size-28;
  }

  .subtitle-container {
    height: $size-51;
    max-width: $size-420;
    margin: 0 auto $size-28;

    p {
      display: inline-block;

      span {
        font-family: $font-semibold;
      }
    }
  }

  .order-steps-container {
    position: relative;
    left: $size-16;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    text-align: left;

    .progress-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: $size-5;
      margin-bottom: $size-9;
      justify-content: space-between;

      &.active {
        + p {
          color: $order-status-active;
        }
      }

      + p {
        max-width: $size-70;
        font-family: $font-semibold;
      }

      svg {
        margin-right: $size-7;
      }

      .progress-bar {
        width: $size-91;
        height: $size-5;
        background-color: $progress-bar-bg-color;
        border-radius: $size-4;
        overflow: hidden;
        position: relative;

        .progress-bar-inner {
          position: absolute;
          width: 0;
          height: 100%;
          background-color: $order-status-active;
          border-radius: inherit;

          &.ease-in {
            width: 100%;
            left: -100%;
            animation: slideInLeft100 1.5s forwards;
          }

          &.ease-out {
            width: 50%;
            left: -50%;
            animation: slideInLeft50 1.5s forwards;
            animation-delay: 1.5s;
          }
        }
      }
    }
  }

  .button-container {
    margin: 0 auto $size-39;
  }

  .what-happens-next-items {
    text-align: left;
    width: $size-624;
    margin: 0 auto;

    ol {
      margin: 0;
      padding-left: $size-20;
    }

    li {
      &::marker {
        font-size: $size-14;
        color: $dark-color;
        font-family: $font-medium;
      }
    }
  }
}
