@import '../../styles/Reset';

$success-color: #8acb88;
$error-color: #c65054;

.doo-snackbar {
  border-radius: $size-6;
  top: $size-20 !important;

  .MuiAlert-root {
    padding: $size-11 $size-18;
    max-height: $size-42;
    border-radius: $size-6;
  }

  .MuiAlert-filled {
    box-shadow: 0 $size-4 $size-10 rgba(0, 0, 0, 0.25);
  }

  .MuiAlert-message {
    font-size: $size-14;
    font-family: $font-semibold;
    line-height: $size-20;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $size-12;
    overflow: initial;
  }

  .MuiAlert-filledSuccess {
    background-color: $light-color;
  }

  .MuiAlert-filledError {
    background-color: $error-color;

    svg {
      path {
        fill: $white-color;
      }
    }
  }
}