@import '../../styles/Reset';

.public-gallery-settings {
  margin-top: $size-40;

  input {
    border: none;
    background-color: $table-gray-color;
  }

  &-section {
    padding: $size-32 0;

    .section-title {
      margin-bottom: $size-32;

      p {
        font-size: $size-20;
        color: $dark-color;
        font-family: $font-semibold;
      }
    }

    .MuiFormControlLabel-label {
      font-family: $font;
    }
  }

  .bottom-action-buttons {
    display: flex;
    justify-content: space-between;
    gap: $size-20;
    margin: $size-40 0;

    button {
      margin-left: $size-10;
    }
  }
}