@import '../../../../styles/Reset';

.new-order--save-template {
  display: flex;
  margin-top: $size-20;

  button {
    margin-left: auto;
    padding: $size-9 $size-14;
    background-color: #E0E0E0;
    color: #667085;
  }

  button:hover {
    background-color: #c7c7c7;
  }
}