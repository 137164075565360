@import '../../styles/Reset';

$tab-active-color: #FFFFFF;
$tab-bg-color: #FFFFFF;
$tab-active-bg-color: #447AF0;

.profile-page-tabs {
  display: flex;
  margin-bottom: $size-22;
  margin-top: $size-50;
  gap: $size-2;

  @media (min-width: $mobile-breakpoint-width) {
    margin-top: 0;
    margin-bottom: $size-36;
  }

  .button-container {
    position: relative;
    overflow: visible;

    .info-icon {
      position: absolute;
      right: 0;
      top: (-$size-6);
    }
  }

  .active {
    button {
      background-color: $tab-active-bg-color;
      color: $tab-active-color;
      font-family: $font-semibold;

      &:hover {
        background-color: darken($tab-active-bg-color, 10%);
      }
    }
  }

  button {
    color: $dark-color;
    background-color: $tab-bg-color;
    height: $size-40;
    font-family: $font;
    padding: 0 $size-16;
    box-shadow: none;
    line-height: $size-13;

    @media (max-width: $mobile-breakpoint-width) {
      white-space: nowrap;
      font-size: $size-14;
      height: $size-36;
      line-height: $size-13;
      padding: 0 $size-14;
    }

    &:hover {
      background-color: darken($tab-bg-color, 10%);
    }
  }
}