@import '../../styles/Reset';

.h1 {
  font-size: $size-96;
}

.h2 {
  font-size: $size-60;
}

.h3 {
  font-size: $size-48;
}

.h4 {
  font-size: $size-34;
}

.h5 {
  font-size: $size-24;
}

.h6 {
  font-size: $size-20;
  line-height: $size-30;
}

.body1 {
  font-size: $size-12;
  line-height: $size-17;
}

.caption {
  font-size: $size-13;
  line-height: $size-18;
}

.xs {
  font-size: $size-14;
  line-height: $size-20;
}

.xs-narrow {
  font-size: $size-14;
  line-height: $size-18;
}

.subtitle, .s {
  font-size: $size-16;
  line-height: $size-24;
}

.subtitle-narrow {
  font-size: $size-16;
  line-height: $size-18;
}

.title, .xl {
  font-size: $size-30;
  line-height: $size-38;
}

.no-wrap {
  white-space: nowrap;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.light {
  color: $light-color;
}

.lighter {
  color: $lighter-color;
}

.dark {
  color: $dark-color;
}

.error {
  color: $input-error-color;
}

.not-logged-page {
  color: $not-logged-light-color;

  .light {
    color: $not-logged-light-color;
  }

  .dark {
    color: $not-logged-dark-color;
  }
}
