@import '../../../styles/Reset';

.public-gallery-settings {
  &-section.data-transfer {
    .switch-columns {
      display: flex;
      gap: $size-18;

      .switch-column {
        display: flex;
        gap: $size-8;

        &:first-child {
          width: 33%;
        }

        &-title {
          width: $size-100;

          p {
            font-size: $size-16;
            color: $dark-color;
            font-family: $font-semibold;
          }
        }

        .switch-group {
          display: flex;
          flex-direction: column;
          gap: $size-24;

          label {
            display: flex;
            align-items: center;

            p {
              font-size: $size-14;
              color: $dark-color;
            }
          }

          .MuiFormGroup-root {
            margin-left: $size-40;

            .MuiFormControlLabel-root {
              margin: $size-4 0;
            }
          }

          .input-rows {
            display: flex;
            flex-direction: column;
            gap: $size-12;

            .input-row {
              display: flex;
              gap: $size-40;

              .input-box {
                display: flex;

                p {
                  width: $size-83;
                  font-size: $size-14;
                  color: $dark-color;
                  font-family: $font-semibold;
                }

                &.small p {
                  width: $size-48;
                }

                .input--wrap {
                  width: $size-240;
                }

                .password-input-wrapper {
                  position: relative;

                  button {
                    position: absolute;
                    background-color: transparent;
                    bottom: (-$size-28);
                    left: $size-12;

                    p {
                      font-size: $size-14;
                      color: $dark-color-opacity-40;
                      font-family: $font-medium;
                      white-space: nowrap;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}