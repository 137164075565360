@import "../../../../styles/Reset";

$image-preview-vertical-max-width: $size-200;
$image-preview-horizontal-max-width: $size-120;
$image-preview-max-height: $size-160;

.order-table-content {
  .imagesDetail-slidesWrapper {
    display: flex;
    height: $image-preview-max-height;

    &.slides-840 {
      width: $size-948;
    }

    &.slides-480 {
      width: $size-558;
    }

    &.mobile {
      height: $size-220;
      position: relative;

      .imagesDetail-leftArrow {
        position: absolute;
        left: 0;
        bottom: $size-4;
      }

      .imagesDetail-rightArrow {
        position: absolute;
        right: 0;
        bottom: $size-4;
      }
    }

    .imagesDetail-leftArrow,
    .imagesDetail-rightArrow {
      background-color: transparent;
      width: $size-24;

      &.disabled {
        opacity: 0.3;
      }
    }

    .imagesDetail-leftArrow {
      &.disabled {
        opacity: 0;
      }
    }

    .imagesDetail-rightArrow {
      margin-left: auto;
    }

    .imagesDetail-slides {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      gap: $size-10;

      .imagesDetail-slide {
        position: relative;
        display: flex;
        align-items: center;

        img {
          max-height: $image-preview-max-height;

          &.vertical {
            max-width: $image-preview-vertical-max-width;
          }

          &.horizontal {
            max-width: $image-preview-horizontal-max-width;
          }

          &.square {
            width: $image-preview-max-height;
          }
        }
      }
    }
  }
}