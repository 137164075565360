@import "../../../styles/Reset";

$link-color: #447AF0;

.order-table-content {
  .order-detail-mobile-text {
    padding: $size-19 $size-14;
    color: $dark-color;
  }

  .content-imagesDetail {
    display: flex;
  }

  .mobile-input-output-buttons {
    display: flex;
    justify-content: center;
    position: relative;

    .buttons-wrapper {
      display: flex;
      position: absolute;
      bottom: $size-8;
      gap: $size-12;

      button {
        width: $size-45;
        height: $size-30;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $size-6;
        background-color: $white-color;

        &.active {
          background-color: $table-gray-color;
        }
      }
    }
  }

  .content-viewAllImagesButton {
    display: flex;
    justify-content: flex-end;
    margin: $size-16 $size-34 $size-25;

    a {
      font-size: $size-11;
      line-height: $size-12;
      font-family: $font-semibold;
      color: $dark-color;
      text-decoration: none;
      border-bottom: $size-1 solid;
    }
  }

  .content-bottom {
    padding: $size-20 $size-33 $size-25 $size-33;
    border-top: $size-1 solid $table-row-border-color;
    display: flex;
    gap: $size-51;

    .order-details {
      max-width: $size-715;
      width: 100%;
      color: $dark-color;

      .order-details-top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: $size-14;

        .order-details-top-icons {
          position: relative;
          top: $size-2;
          display: flex;
          gap: $size-15;
          margin-right: $size-8;

          button {
            background-color: transparent;
          }
        }
      }
    }
  }

  .new-template-dialog {
    display: flex;
    flex-direction: column;
    gap: $size-20;
    text-align: left;
    word-break: $break-word;
  }

  .template-exist-warning {
    .a {
      color: $link-color;
      font-family: $font-semibold;
    }
  }

  .new-template-dialog-container {
    .h6 {
      color: $dark-color;
    }
  }
}

.dashboard-table-loading {
  height: $size-70;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-dots {
    width: auto;

    .loading-dots--dot {
      width: $size-10;
      height: $size-10;

    }
  }
}