@import '../../../../styles/Reset';

$seperator-color: #E9E9E9;

.template-management {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  max-height: $size-453;

  .template-list-wrapper {
    padding-right: $size-16;
  }

  .template-list {
    padding-top: $size-6;
    padding-bottom: $size-6;

    .template-button {
      border-radius: $size-4;
      padding: $size-7 $size-24 $size-7 $size-14;
      display: flex;
      justify-content: space-between;

      &-actions {
        display: flex;
        align-items: center;

        .detail-view-btn {
          background-color: transparent;
          display: flex;
          margin-right: $size-12;

          svg path {
            fill: $light-color;
          }
        }

        .MuiCheckbox-root {
          padding: 0;
        }
      }
    }

  }

  .seperator {
    border-top: $size-1 solid $seperator-color;
    margin: $size-9 0 $size-9 $size-14;
  }
}