@import '../../styles/Reset';

.MuiButtonBase-root.MuiRadio-root {
  padding: 0 $size-9;
}

.doo-label .MuiFormControlLabel-label {
  font-family: $font-medium;
  font-size: $size-14;
  line-height: $size-18;
  margin-right: $size-36;
  color: $input-label-color;
}

.doo-radio .MuiSvgIcon-root {
  color: $input-label-color;
}

.not-logged-page {
  .doo-label .MuiFormControlLabel-label {
    color: $not-logged-input-label-color;
  }

  .doo-radio .MuiSvgIcon-root {
    color: $not-logged-input-label-color;
  }
}
