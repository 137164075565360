@import '../../styles/Reset';

$unchecked-color: #D0D5DD;

.new-order-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .doo-checkbox input[type="checkbox"]:focus+svg {
    box-shadow: none;
    border: none;

    rect {
      stroke: $unchecked-color;
    }
  }

  .doo-checkbox.Mui-checked input[type="checkbox"]:focus+svg {
    rect {
      stroke: $dark-color;
    }
  }

  .section-content {
    border: $size-1 solid $input-border-color;

    .section-content-columns {
      display: flex;

      .section-content-column {
        padding: 0 $size-14 $size-14;
        margin: $size-20 0;
        width: 25%;

        &:not(:first-child) {
          border-left: $size-1 solid $table-row-border-color;
        }

        .column-check-label {
          display: flex;
          align-items: center;
        }

        .column-inputs {
          display: flex;
          flex-direction: column;
          gap: $size-11;
        }

        .column-input {
          display: flex;
          align-items: center;
          gap: $size-8;
          color: $dark-color;

          input {
            border: none;
            background-color: $table-gray-color;
          }
        }

        .column-select {
          margin-bottom: $size-11;
        }

        .column-content {
          padding-left: $size-20;
        }

        .column-brand {
          display: flex;
          align-items: center;
          gap: $size-15;
          cursor: pointer;
          margin-bottom: $size-20;

          img {
            max-height: $size-18;
          }
        }

        .MuiFormGroup-root {
          gap: $size-15;

          .MuiFormControlLabel-label {
            font-family: $font;
          }
        }
      }
    }
  }

  .new-order-loading {
    display: flex;
    justify-content: center;
    padding: $size-40 0;
    background-color: $table-row-border-color;
    border: $size-1 solid $input-border-color;
    border-radius: 0 0 $size-8 $size-8;

    .loading-dots {
      width: auto;
    }
  }


  .error-widget {
    display: grid;
    gap: $size-4;
    background-color: $orange-500-color;
    color: black;
    padding: $size-10 $size-26;
    width: $size-300;
    max-height: $size-100;
    overflow-y: auto;
    border-radius: $size-8 $size-8 0 0;
    position: fixed;
    bottom: 0;
    z-index: 2;
    transition: 0.3s;

    &:hover {
      max-height: $size-200;
    }

    @media (min-width: 1200px) {
      width: $size-600;
    }

    @media (min-width: 1440px) {
      width: $size-800;
    }

    .error-widget-section {
      ul {
        list-style: disc;
        padding-left: $size-12;
        color: $gray-900-color;
      }
    }
  }


  .price-badge {
    background-color: $table-row-border-color;
    border-radius: $size-16;
    padding: $size-2 $size-10;
    color: $dark-color;
    font-size: $size-10;
    white-space: nowrap;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
    padding-right: $size-40;
  }

  // input number hide arrows
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    padding-right: 0;
  }

  .input-number-no-padding[type=number] {
    padding-right: $size-12;
  }

  .accordion {
    box-shadow: none;
    margin: 0 !important;

    .accordion-summary {
      min-height: auto;
      padding: 0;

      .MuiAccordionSummary-content {
        margin: 0;
      }
    }

    .accordion-details {
      padding: 0;
    }
  }

  .billing-address-modal {
    .modal--wrap {
      z-index: $z-index-1;
    }

    .modal {
      padding: $size-27 $size-40 $size-30;
    }
  }

}

.new-order-modal {
  .modal--wrap {
    z-index: $modal-z-index;
    position: fixed;
    background-color: $modal-bg-color;

    .new-order-modal-title {
      text-align: center;
      margin-bottom: $size-8;

      h6 {
        color: $dark-color;
      }
    }

    .new-order-modal-content {
      margin-bottom: $size-32;
      text-align: center;

      &>p {
        text-align: center;
        display: grid;
        gap: $size-20;
      }
    }

    .test-order-modal-content {
      list-style: decimal;
      padding-left: $size-20;

      li {
        margin-bottom: $size-20;
        color: $light-color;

        p {
          line-height: $size-20;
        }
      }
    }

    .new-order-modal-buttons {
      display: flex;
      gap: $size-14;
    }
  }
}

.new-order-select-item {
  color: $dark-color !important;
}

.new-order-error_highlighted td {
  text-align: center;
  vertical-align: top;
  font-size: $size-14;
  line-height: $size-20;
}

.new-order-error_highlighted td:before {
  content: '\00B7';
  padding-right: $size-6;
  font-size: $size-20;
  vertical-align: top;
}

.new-order-error_subtitle {
  font-family: $font-semibold;
  font-size: $size-14;
}

.previous-order-desc {
  display: flex;
  margin: 0 auto;
  width: $size-360;
  max-width: $size-360;

  div:first-child {
    width: $size-100;
  }

  svg {
    margin-top: $size-18;
  }
}
