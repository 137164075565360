@import '../../styles/Reset';

.a, .a.light {
  cursor: pointer;
  color: $light-color;
  text-decoration: none;
}

.a:hover, .a.light:hover {
  color: $light-color;
  text-decoration: none;
  border-bottom: $size-1 solid;
}

.a.dark {
  color: $dark-color;
}

.a.dark:hover {
  text-decoration: none;
  border-bottom: $size-1 solid;
}

.a--underline,
.a--underline.light,
.a--underline.dark {
  text-decoration: none;
  border-bottom: $size-1 solid;
}

.not-logged-page {
  .a, .a.light {
    cursor: pointer;
    color: $not-logged-light-color;
    text-decoration: none;
  }

  .a:hover, .a.light:hover {
    color: $not-logged-light-color;
    text-decoration: none;
    border-bottom: $size-1 solid;
  }

  .a.dark {
    color: $not-logged-dark-color;
  }

  .a.dark:hover {
    text-decoration: none;
    border-bottom: $size-1 solid;
  }

  .a--underline, .a--underline.light {
    text-decoration: none;
    border-bottom: $size-1 solid;
  }

  .a--underline.dark {
    text-decoration: none;
    border-bottom: $size-1 solid;
  }
}