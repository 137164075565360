@import '../../../styles/Reset.scss';

.billing-address-container {
  height: $size-563;
  display: flex;
  flex-direction: column;

  .billing-address-title {
    margin-bottom: $size-24;
  }

  .billing-address-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: $size-19;

    .billing-address-form-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: $size-21;

      .billing-address-form-row-item {
        display: flex;
        align-items: center;
        width: 100%;

        .input--wrap {
          width: 100%;
        }
      }
    }
  }

  .billing-address-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: $size-12;

    .button {
      width: auto;
      min-width: $size-116;
      line-height: $size-13;
    }
  }
}