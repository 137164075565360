@import "../../../../styles/Reset";

$pricing-summary-width: $size-420;

.order-table-content {
  .pricing-summary {
    width: $pricing-summary-width;
    color: $dark-color;

    .pricing-summary-title {
      margin-bottom: $size-14;
    }

    .pricing-summary-table {
      border: $size-1 solid $table-row-border-color;

      table {
        min-width: $pricing-summary-width;
      }

      thead th {
        background-color: $table-row-border-color;
        padding: $size-8 $size-15 $size-12 $size-15;
      }

      tbody tr:not(.format-row) td:first-child,
      tbody tr:last-child td:nth-child(2) {
        padding-right: 0;
      }

      thead th:nth-child(3),
      tbody .format-row td:nth-child(3) {
        padding-left: $size-32;
        text-align: left;
      }

      tbody tr:last-child td:first-child {
        padding-left: $size-20;
      }

      tbody td {
        padding: $size-7 $size-15;
        text-align: center;
      }

      tbody tr:first-child td {
        padding-top: $size-13;
      }

      tbody tr:last-child td {
        padding-bottom: $size-13;
      }

      .table-cell-total-line {
        background-color: $debtBox-corrected-bg-color;
        width: 75%;
        height: $size-1;
        position: absolute;
        top: 0;
        right: $size-15;
      }

      .table-cell-gross-line {
        background-color: $input-color;
        width: $size-60;
        height: $size-2;
        position: absolute;
        top: 0;
        right: $size-15;
      }

      .summary-table-perImage {
        position: relative;
        padding-right: $size-8;

        svg {
          position: absolute;
          top: $size-1-5;
          right: (-$size-14);
          width: $size-15;
          height: $size-15;
        }
      }
    }
  }
}

.perImage-title {
  display: flex;
  justify-content: center;
  margin-bottom: $size-10;
  text-transform: uppercase;
}

.perImage-icon-tooltip {
  padding: 0 $size-2;
  margin: 0 $size-2;
  width: $size-230;
  border-radius: $size-4;

  .perImage-icon-tooltip-item {
    display: flex;
    justify-content: space-between;
    padding: $size-4 0;

    &:not(:first-child) {
      border-top: $size-1 solid $white-opacity-50;
    }
  }

  .perImage-icon-tooltip-total {
    display: flex;
    justify-content: flex-end;

    p {
      display: inline-block;
      padding: $size-4 0;
      border-top: $size-1 solid $white-color;
    }
  }
}