@import '../../../styles/Reset';

@mixin flex-items-center {
  display: flex;
  align-items: center;
}

$copy-border-color: #D0D5DD;

.reset-password-modal {
  @include flex-items-center();
  flex-direction: column;
  text-align: center;
  min-height: $size-285;

  .reset-title-container {
    margin-bottom: $size-8;

    h6 {
      color: $dark-color;
    }
  }

  .reset-description-container {
    margin-bottom: $size-23;
  }

  .reset-button-container {
    @include flex-items-center();
    flex-direction: column;
    gap: $size-4;
    margin-bottom: $size-24;

    button {
      font-family: $font;
      width: auto;
      min-width: $size-267;
    }

  }

  .please-note {
    p {
      b {
        font-size: $size-14;
      }
    }
  }

  .copy-container {
    flex: 1;

    .input-view {
      @include flex-items-center();
      flex-direction: row;
      flex-wrap: nowrap;
      border: $size-1 solid $copy-border-color;
      border-radius: $size-4;
      width: $size-267;
      height: $size-36;
      padding: $size-9 $size-14;
      margin-top: $size-17;

      p {
        width: 100%;
      }

      button {
        background-color: transparent;
        width: auto;
        height: $size-25;
        padding: 0;
        box-shadow: none;

        .copy-icon {
          margin-left: auto;
        }
      }
    }
  }

  .reset-password-container {
    display: flex;
    flex-direction: column;
    gap: $size-20;
    margin-bottom: $size-35;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: $size-16;
    width: 100%;

    button {
      width: 100%;
    }
  }
}