@import '../../styles/Reset';

.support-menu--wrap {
  z-index: $support-menu--z-index;

  position: absolute;
  top: $size-27;
  right: 0;

  .support-menu {
    position: relative;
    text-align: right;

    height: $size-40;

    .support-menu--icon {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;

      cursor: pointer;
      width: $header-icon-height;
      height: $header-icon-height;

      background: url("../../svg/faq.svg") center center/cover no-repeat;
    }
  }

  .support-menu__menu {
    padding: $size-13 $size-12;
    border: $size-1 solid #EAECF0;
    background-color: $white-color;
    box-shadow: 0 $size-12 $size-16 (-$size-4) rgba(16, 24, 40, .08), 0 $size-4 $size-6 (-$size-2) rgba(16, 24, 40, .03);
    border-radius: $size-8;
  }

  .support-menu__menu-item {
    position: relative;
    cursor: pointer;
    height: $size-36;
    border-radius: $size-8;
    padding-left: $size-47;
    padding-right: $size-31;
    margin-bottom: $size-2;
  }

  .support-menu__menu-item p {
    color: $dark-color !important;
    font-family: $font-medium;
    white-space: nowrap;
  }

  .support-menu__menu-item:hover {
    background-color: $hover-bg-color;
  }

  .support-menu__menu-item--active {
    background-color: $hover-bg-color;
  }

  .support-menu__menu-item p {
    line-height: $size-36;
  }

  .support-menu__menu-item svg {
    position: absolute;
    left: $size-11;
    top: $size-8;

    path {
      fill: #475467;
    }
  }

  .support-menu__menu-item svg.top-10 {
    top: $size-10;
  }
}
