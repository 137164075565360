/******************************************************/
$mobile-breakpoint-width: 431px;
$tablet-breakpoint-width: 881px;
$desktop-breakpoint-width: 1024px;
$hd-breakpoint-width: 2048px;

$register-ad-breakpoint-width: 1024px;
$login-ad-breakpoint-width: 1024px;
$support-image-breakpoint-width: 1160px;

$sidebar-breakpoint-width: 1620px;

:root {
  --root-size: 11px;
}

/* mobile devices */
@media (min-width: $mobile-breakpoint-width) {
  :root {
    --root-size: 13px;
  }
}

/* tablet devices */
@media (min-width: $tablet-breakpoint-width) {
  :root {
    --root-size: 13px;
  }
}

/* desktop devices */
@media (min-width: $desktop-breakpoint-width) {
  :root {
    --root-size: 13px;
  }
}

/* hd devices */
@media (min-width: $hd-breakpoint-width) {
  :root {
    --root-size: 13px;
  }
}

/******************************************************/
$size-1: 1px;
$size-1-5: 0.1153846rem;
$size-2: 0.1538461rem;
$size-3: 0.2307692rem;
$size-4: 0.3076923rem;
$size-5: 0.3846153rem;
$size-6: 0.4615384rem;
$size-7: 0.5384615rem;
$size-8: 0.6153846rem;
$size-9: 0.6923076rem;
$size-10: 0.7692307rem;
$size-11: 0.8461538rem;
$size-12: 0.9230769rem;
$size-13: 1.0000000rem;
$size-14: 1.0769230rem;
$size-15: 1.1538461rem;
$size-16: 1.2307692rem;
$size-17: 1.3076923rem;
$size-18: 1.3846153rem;
$size-19: 1.4615384rem;
$size-20: 1.5384615rem;
$size-21: 1.6153846rem;
$size-22: 1.6923076rem;
$size-23: 1.7692307rem;
$size-24: 1.8461538rem;
$size-25: 1.9230769rem;
$size-26: 2.0000000rem;
$size-27: 2.0769230rem;
$size-28: 2.1538461rem;
$size-29: 2.2307692rem;
$size-30: 2.3076923rem;
$size-31: 2.3846153rem;
$size-32: 2.4615384rem;
$size-33: 2.5384615rem;
$size-34: 2.6153846rem;
$size-35: 2.6923076rem;
$size-36: 2.7692307rem;
$size-37: 2.8461538rem;
$size-38: 2.9230769rem;
$size-39: 3.0000000rem;
$size-40: 3.0769230rem;
$size-41: 3.1538461rem;
$size-42: 3.2307692rem;
$size-43: 3.3076923rem;
$size-44: 3.3846153rem;
$size-45: 3.4615384rem;
$size-46: 3.5384615rem;
$size-47: 3.6153846rem;
$size-48: 3.6923076rem;
$size-50: 3.8461538rem;
$size-51: 3.9230769rem;
$size-52: 4.0000000rem;
$size-54: 4.1538461rem;
$size-55: 4.2307692rem;
$size-56: 4.3076923rem;
$size-58: 4.4615384rem;
$size-59: 3.6875000rem;
$size-60: 4.6153846rem;
$size-61: 4.6923076rem;
$size-62: 4.7692307rem;
$size-64: 4.9230769rem;
$size-65: 5.0000000rem;
$size-66: 5.0769230rem;
$size-68: 5.2307692rem;
$size-70: 5.3846153rem;
$size-72: 5.5384615rem;
$size-73: 5.6153846rem;
$size-74: 5.6923076rem;
$size-76: 5.8461538rem;
$size-78: 6.0000000rem;
$size-80: 6.1538461rem;
$size-83: 6.3846153rem;
$size-90: 6.9230769rem;
$size-91: 7.0000000rem;
$size-92: 7.0769230rem;
$size-93: 7.1538461rem;
$size-94: 7.2307692rem;
$size-96: 7.3846153rem;
$size-97: 7.4615384rem;
$size-100: 7.6923076rem;
$size-105: 8.0769230rem;
$size-109: 8.3846153rem;
$size-110: 8.4615384rem;
$size-112: 8.6153846rem;
$size-114: 8.7692307rem;
$size-115: 8.8461538rem;
$size-116: 8.9230769rem;
$size-118: 9.0769230rem;
$size-120: 9.2307692rem;
$size-122: 9.3846153rem;
$size-124: 9.5384615rem;
$size-125: 9.6153846rem;
$size-126: 9.6923076rem;
$size-130: 10.000000rem;
$size-132: 10.153846rem;
$size-135: 10.384615rem;
$size-137: 10.538461rem;
$size-140: 10.769230rem;
$size-144: 11.076923rem;
$size-145: 11.153846rem;
$size-148: 11.384615rem;
$size-150: 11.538461rem;
$size-152: 11.692307rem;
$size-155: 11.923076rem;
$size-157: 12.076923rem;
$size-158: 12.153846rem;
$size-159: 12.230769rem;
$size-160: 12.307692rem;
$size-165: 12.692307rem;
$size-168: 12.923076rem;
$size-170: 13.076923rem;
$size-171: 13.153846rem;
$size-172: 13.230769rem;
$size-174: 13.384615rem;
$size-176: 13.538461rem;
$size-177: 13.615384rem;
$size-180: 13.846153rem;
$size-181: 13.923076rem;
$size-183: 14.076923rem;
$size-186: 14.307692rem;
$size-190: 14.615384rem;
$size-193: 14.846153rem;
$size-198: 15.230769rem;
$size-200: 15.384615rem;
$size-204: 15.692307rem;
$size-210: 16.153846rem;
$size-215: 16.538461rem;
$size-220: 16.923076rem;
$size-230: 17.692307rem;
$size-234: 18.000000rem;
$size-240: 18.461538rem;
$size-250: 19.230769rem;
$size-256: 19.692307rem;
$size-260: 20.000000rem;
$size-265: 20.384615rem;
$size-267: 20.538461rem;
$size-270: 20.769230rem;
$size-275: 21.153846rem;
$size-280: 21.538461rem;
$size-283: 21.769230rem;
$size-285: 21.923076rem;
$size-287: 22.076923rem;
$size-288: 22.153846rem;
$size-290: 22.307692rem;
$size-298: 22.923076rem;
$size-300: 23.076923rem;
$size-314: 24.153846rem;
$size-328: 25.230769rem;
$size-320: 24.615384rem;
$size-322: 24.769230rem;
$size-330: 25.384615rem;
$size-332: 25.538461rem;
$size-346: 26.615384rem;
$size-348: 26.769230rem;
$size-350: 26.923076rem;
$size-352: 27.076923rem;
$size-360: 27.692307rem;
$size-362: 27.846153rem;
$size-365: 28.076923rem;
$size-380: 29.230769rem;
$size-390: 30.000000rem;
$size-400: 30.769230rem;
$size-411: 31.615384rem;
$size-420: 32.307692rem;
$size-447: 34.384615rem;
$size-450: 34.615384rem;
$size-453: 34.846153rem;
$size-454: 34.923076rem;
$size-460: 35.384615rem;
$size-462: 35.538461rem;
$size-466: 35.846153rem;
$size-475: 36.538461rem;
$size-480: 36.923076rem;
$size-490: 37.692307rem;
$size-500: 38.461538rem;
$size-520: 40.000000rem;
$size-525: 40.384615rem;
$size-530: 40.769230rem;
$size-538: 41.384615rem;
$size-550: 42.307692rem;
$size-558: 43.076923rem;
$size-560: 43.076923rem;
$size-563: 43.307692rem;
$size-566: 43.538461rem;
$size-570: 43.846153rem;
$size-575: 44.230769rem;
$size-578: 44.461538rem;
$size-587: 45.230769rem;
$size-592: 45.538461rem;
$size-598: 46.000000rem;
$size-600: 46.153846rem;
$size-614: 47.230769rem;
$size-620: 47.692307rem;
$size-624: 48.000000rem;
$size-630: 48.461538rem;
$size-638: 49.076923rem;
$size-645: 49.615384rem;
$size-700: 53.846153rem;
$size-715: 55.000000rem;
$size-768: 59.076923rem;
$size-800: 61.538461rem;
$size-840: 64.615384rem;
$size-900: 69.230769rem;
$size-938: 72.307692rem;
$size-948: 72.923076rem;
$size-1000: 76.92307rem;
$size-1248: 96.00000rem;

/******************************************************/
@font-face {
  font-family: 'roobert';
  src: url('../../public/fonts/Roobert-Regular.woff2') format('woff2'), url('../../public/fonts/Roobert-Regular.woff') format('woff'), url('../../public/fonts/Roobert-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'roobert-italic';
  src: url('../../public/fonts/Roobert-RegularItalic.woff2') format('woff2'), url('../../public/fonts/Roobert-RegularItalic.woff') format('woff'), url('../../public/fonts/Roobert-RegularItalic.otf') format('opentype');
}

@font-face {
  font-family: 'roobert-medium';
  src: url('../../public/fonts/Roobert-Medium.woff2') format('woff2'), url('../../public/fonts/Roobert-Medium.woff') format('woff'), url('../../public/fonts/Roobert-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'roobert-semibold';
  src: url('../../public/fonts/Roobert-SemiBold.woff2') format('woff2'), url('../../public/fonts/Roobert-SemiBold.woff') format('woff'), url('../../public/fonts/Roobert-SemiBold.otf') format('opentype');
}

$font: roobert, helvetica, arial, sans-serif;
$font-italic: roobert-italic;
$font-medium: roobert-medium;
$font-semibold: roobert-semibold;

i {
  font-family: $font-italic;
}

strong, b {
  font-family: $font-semibold;
}

.roobert-italic {
  font-family: $font-italic;
}

.roobert-medium {
  font-family: $font-medium;
}

.roobert-semibold {
  font-family: $font-semibold;
}

@mixin font-mixing {
  font-family: $font;
  font-weight: 400;
  font-size: var(--root-size);
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/******************************************************/
$bg-color: #FFFFFF;
$white-color: #FFFFFF;

$modal-bg-color: #66708575;
$modal-subtitle-color: #ffa117;

$lighter-color: #E2E2E2;
$light-color: #667085;
$dark-color: #475467;
$light-grey-color: #808185;

$gray-700-color: #344054;
$gray-900-color: #101828;

$modal-textarea-bg-color: #F8F8F8;

$gray-text-bg-color: #EAEAEA;

$table-status-hover-bg-color: #35424FE5;
$table-status-hover-icon-color: #495460;

$table-gray-color: #f9fafb;
$table-row-border-color: #EAECF0;
$table-chip-bg-color: #E6F6FF;

$debtBox-paid-bg-color: #ECFDF3;
$debtBox-paid-color: #027A48;

$debtBox-unpaid-bg-color: #F4DCDD;
$debtBox-unpaid-color: #C65054;

$debtBox-processing-color: #ffa119;
$debtBox-processing-bg-color: #ffecd2;

$debtBox-corrected-bg-color: #D0D5DD;
$debtBox-corrected-color: #475467;

$filefront-gray-color: #CDCDCD;
$box-menu-item-bg-hover-color: #D0D5DD4D;
$new-order-name-input-placeholder-color: #47546766;
$dark-color-opacity-30: #4754674D;
$dark-color-opacity-40: #47546766;

$status-gray-color: #D2D2D2;

$success-300-color: #A0E2C3;
$success-400-color: #59CD97;
$success-500-color: #12B76A;
$success-600-color: #0FA860;

$orange-400-color: #F8B68F;
$orange-500-color: #FFA117;
$orange-700-color: #FF7A00;

$hover-bg-color: rgba(208, 213, 221, .3);

$not-logged-light-color: #3A3183;
$not-logged-dark-color: #231975;

$input-label-color: $dark-color;
$input-color: #667085;
$input-disabled-color: rgba(102, 112, 133, 0.5);
$input-placeholder-color: #808185;
$input-border-color: #D0D5DD;
$input-error-color: #C65054;
$input-focus-color: #447AF0;
$input-warning-bg-color: #C65054;
$input-warning-bg-hover-color: #9f3337;

$white-opacity-50: #FFFFFF80;
$switch-color: #6D7985;
$switch-active-color: #7199C8;
$switch-active-dot-color: #A8CAED;
$switch-active-line-color: #6693CA;
$light-blue-color: #c5e0ff;
$gray-900-color: #242527;

$textarea-label-color: $input-label-color;
$textarea-color: $input-color;
$textarea-placeholder-color: $input-placeholder-color;
$textarea-border-color: $input-border-color;
$textarea-error-color: $input-error-color;
$textarea-focus-color: $input-focus-color;
$textarea-warning-bg-color: $input-warning-bg-color;
$textarea-warning-bg-hover-color: $input-warning-bg-hover-color;

$checkbox-focus-color: $input-focus-color;

$not-logged-input-label-color: $not-logged-dark-color;
$not-logged-textarea-label-color: $not-logged-dark-color;

/******************************************************/
* {
  @include font-mixing;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

$text-selection-color: #FFFFFF;
$text-selection-bg: #4bc0ff;

::-moz-selection {
  color: $text-selection-color;
  background: $text-selection-bg;
}

::selection {
  color: $text-selection-color;
  background: $text-selection-bg;
}

html,
body {
  margin: 0;
  padding: 0;
  color: $light-color;
  background-color: $bg-color;
  @include font-mixing;
}

button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  @include font-mixing;
}

button:hover {
  transition: background-color .5s;
}

.bg-color-transition {
  transition: background-color .5s;
}

table {
  border-spacing: initial;
}

p,
span {
  margin: 0;
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.4;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul,
ol {
  line-height: 1.75;
}

svg,
img {
  outline: none;
}

label {
  cursor: pointer;
}

.a-no-border {
  text-decoration: none;
}

a {
  color: $dark-color;
  text-decoration: none;
}

a:hover {
  color: $light-color;
}

/******************************************************/
$z-index-1: 1;
$z-index-2: 2;
$z-index-3: 3;
$z-index-4: 4;
$z-index-5: 5;
$z-index-999: 999;
$z-index-1000: 1000;
$z-index-9999: 9999;

$header-z-index: $z-index-5;
$footer-z-index: $z-index-5;

$profile-menu--z-index: $z-index-2;
$lang-menu--z-index: $z-index-3;
$support-menu--z-index: $z-index-4;

$sidebar-z-index: $z-index-1000;
$hamburger-z-index: $z-index-4;

$modal-z-index: $z-index-1000;
$cover-spin-z-index: $z-index-9999; /* should be biggest z-index */

/******************************************************/
$break-word: break-word;

/******************************************************/
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.column-half {
  width: 100%;
}

.screen-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

$center-top: 44%;

.screen-center-top {
  position: absolute;
  top: $center-top;
  left: 50%;
  transform: translate(-50%, (-$center-top));
}

.screen-bottom-left {
  z-index: $sidebar-z-index;
  position: absolute;
  bottom: $size-20;
  left: $size-24;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.error {
  color: $input-error-color;
}

.word-break {
  word-break: $break-word;
}

.no-padding {
  padding: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

/******************************************************/
.min-width-330 {
  min-width: $size-330;
}

/******************************************************/
.width-5 {
  width: $size-5;
}

.width-6 {
  width: $size-6;
}

.width-7 {
  width: $size-7;
}

.width-8 {
  width: $size-8;
}

.width-9 {
  width: $size-9;
}

.width-10 {
  width: $size-10;
}

.width-11 {
  width: $size-11;
}

.width-12 {
  width: $size-12;
}

.width-13 {
  width: $size-13;
}

.width-14 {
  width: $size-14;
}

.width-15 {
  width: $size-15;
}

.width-16 {
  width: $size-16;
}

.width-17 {
  width: $size-17;
}

.width-18 {
  width: $size-18;
}

.width-19 {
  width: $size-19;
}

.width-20 {
  width: $size-20;
}

.width-21 {
  width: $size-21;
}

.width-22 {
  width: $size-22;
}

.width-23 {
  width: $size-23;
}

.width-24 {
  width: $size-24;
}

.width-25 {
  width: $size-25;
}

.width-26 {
  width: $size-26;
}

.width-27 {
  width: $size-27;
}

.width-28 {
  width: $size-28;
}

.width-29 {
  width: $size-29;
}

.width-30 {
  width: $size-30;
}

.width-31 {
  width: $size-31;
}

.width-34 {
  width: $size-34;
}

.width-40 {
  width: $size-40;
}

.width-43 {
  width: $size-43;
}

.width-58 {
  width: $size-58;
}

.width-60 {
  width: $size-60;
}

.width-132 {
  width: $size-132;
}

.width-314 {
  width: $size-314;
}

.width-100vw {
  width: 100vw;
}

/******************************************************/
.height-5 {
  height: $size-5;
}

.height-6 {
  height: $size-6;
}

.height-7 {
  height: $size-7;
}

.height-8 {
  height: $size-8;
}

.height-9 {
  height: $size-9;
}

.height-10 {
  height: $size-10;
}

.height-11 {
  height: $size-11;
}

.height-12 {
  height: $size-12;
}

.height-13 {
  height: $size-13;
}

.height-14 {
  height: $size-14;
}

.height-15 {
  height: $size-15;
}

.height-16 {
  height: $size-16;
}

.height-17 {
  height: $size-17;
}

.height-18 {
  height: $size-18;
}

.height-19 {
  height: $size-19;
}

.height-20 {
  height: $size-20;
}

.height-21 {
  height: $size-21;
}

.height-22 {
  height: $size-22;
}

.height-23 {
  height: $size-23;
}

.height-24 {
  height: $size-24;
}

.height-25 {
  height: $size-25;
}

.height-26 {
  height: $size-26;
}

.height-27 {
  height: $size-27;
}

.height-28 {
  height: $size-28;
}

.height-29 {
  height: $size-29;
}

.height-30 {
  height: $size-30;
}

.height-40 {
  height: $size-40;
}

.height-51 {
  height: $size-51;
}

.height-59 {
  height: $size-59;
}

.height-132 {
  width: $size-132;
}

.height-150 {
  height: $size-150;
}

/******************************************************/
.m-top-auto {
  margin-top: auto;
}

.m-top-4 {
  margin-top: $size-4;
}

.m-top-5 {
  margin-top: $size-5;
}

.m-top-6 {
  margin-top: $size-6;
}

.m-top-7 {
  margin-top: $size-8;
}

.m-top-8 {
  margin-top: $size-8;
}

.m-top-9 {
  margin-top: $size-9;
}

.m-top-10 {
  margin-top: $size-10;
}

.m-top-11 {
  margin-top: $size-11;
}

.m-top-12 {
  margin-top: $size-12;
}

.m-top-13 {
  margin-top: $size-13;
}

.m-top-14 {
  margin-top: $size-14;
}

.m-top-15 {
  margin-top: $size-15;
}

.m-top-16 {
  margin-top: $size-16;
}

.m-top-17 {
  margin-top: $size-17;
}

.m-top-18 {
  margin-top: $size-18;
}

.m-top-19 {
  margin-top: $size-19;
}

.m-top-20,
.m-top-20-mobile {
  margin-top: $size-20;
}

.m-top-21 {
  margin-top: $size-21;
}

.m-top-22 {
  margin-top: $size-22;
}

.m-top-23 {
  margin-top: $size-23;
}

.m-top-24 {
  margin-top: $size-24;
}

.m-top-25 {
  margin-top: $size-25;
}

.m-top-26 {
  margin-top: $size-26;
}

.m-top-27 {
  margin-top: $size-27;
}

.m-top-28 {
  margin-top: $size-28;
}

.m-top-29 {
  margin-top: $size-29;
}

.m-top-30 {
  margin-top: $size-30;
}

.m-top-31 {
  margin-top: $size-31;
}

.m-top-32 {
  margin-top: $size-32;
}

.m-top-35 {
  margin-top: $size-35;
}

.m-top-37 {
  margin-top: $size-37;
}

.m-top-38 {
  margin-top: $size-38;
}

.m-top-40 {
  margin-top: $size-40;
}

.m-top-50 {
  margin-top: $size-50;
}

.m-top-70 {
  margin-top: $size-70;
}

/******************************************************/
.m-bottom-0 {
  margin-bottom: 0;
}

.m-bottom-8 {
  margin-bottom: $size-8;
}

.m-bottom-10 {
  margin-bottom: $size-10;
}

.m-bottom-16 {
  margin-bottom: $size-16;
}

.m-bottom-20 {
  margin-bottom: $size-20;
}

.m-bottom-25 {
  margin-bottom: $size-25;
}

.m-bottom-26 {
  margin-bottom: $size-26;
}

.m-bottom-32 {
  margin-bottom: $size-32;
}

.m-bottom-38 {
  margin-bottom: $size-38;
}

.m-bottom-39 {
  margin-bottom: $size-39;
}

.m-bottom-40 {
  margin-bottom: $size-40;
}

.m-bottom-42 {
  margin-bottom: $size-42;
}

/******************************************************/
.m-left-5 {
  margin-left: $size-5;
}

/******************************************************/
$overflow-overlay: overlay;
/* the same as auto, but with the scrollbars drawn on top of content instead of taking up space */

$scrollbar-bg: #E3E5E8;
$scrollbar-bg-layout: #939393;

$scrollbar-width: $size-6;

.scrollbar-overflow {
  overflow-x: auto;
  overflow-y: auto;

  // This next 2 lines are for Firefox
  @supports (-moz-appearance: none) {
    scrollbar-color: $scrollbar-bg $white-color;
    scrollbar-width: $scrollbar-width;
  }
}

.scrollbar-overflow-layout {
  // This next 2 lines are for Firefox
  @supports (-moz-appearance: none) {
    scrollbar-color: $scrollbar-bg-layout $white-color;
    scrollbar-width: $scrollbar-width;
  }
}

.scrollbar-overflow {
  overflow-x: $overflow-overlay;
  overflow-y: $overflow-overlay;
}

.MuiMenu-paper::-webkit-scrollbar-track,
.scrollbar-overflow::-webkit-scrollbar-track {
  margin-top: 0;
}

.MuiMenu-paper::-webkit-scrollbar,
.scrollbar-overflow::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}

.scrollbar-overflow__zero::-webkit-scrollbar {
  /* can be used in fullscreen view to completely hide scrollbars */
  width: 0;
  height: 0;
}

.MuiMenu-paper::-webkit-scrollbar-thumb,
.scrollbar-overflow::-webkit-scrollbar-thumb {
  border-radius: calc(#{$scrollbar-width} / 2);
  background-color: $scrollbar-bg;
}

.MuiMenu-paper::-webkit-scrollbar-thumb:hover,
.scrollbar-overflow::-webkit-scrollbar-thumb:hover {
  background-color: darken($scrollbar-bg, 5%);
}

.MuiMenu-paper::-webkit-scrollbar-thumb:active,
.scrollbar-overflow::-webkit-scrollbar-thumb:active {
  background-color: darken($scrollbar-bg, 10%);
}

.MuiMenu-paper::-webkit-scrollbar-corner,
.scrollbar-overflow::-webkit-scrollbar-corner {
  background-color: transparent;
}

.scrollbar-overflow-layout::-webkit-scrollbar-thumb {
  background-color: $scrollbar-bg-layout;
}

.scrollbar-overflow-layout::-webkit-scrollbar-thumb:hover {
  background-color: darken($scrollbar-bg-layout, 5%);
}

.scrollbar-overflow-layout::-webkit-scrollbar-thumb:active {
  background-color: darken($scrollbar-bg-layout, 10%);
}

/******************************************************/
$skeleton-speed: 2.25s;

.skeleton {
  background: linear-gradient(90deg, #d7d7d7, #f0f0f0, #d7d7d7, #f0f0f0);
  background-size: 400% 400%;
  animation: skeleton-gradient $skeleton-speed ease infinite;
  min-height: $size-10;
}

@-webkit-keyframes skeleton-gradient {
  0% {
    background-position: 100% 50%
  }

  50% {
    background-position: 0 50%
  }

  100% {
    background-position: 100% 50%
  }
}

@-moz-keyframes skeleton-gradient {
  0% {
    background-position: 100% 50%
  }

  50% {
    background-position: 0 50%
  }

  100% {
    background-position: 100% 50%
  }
}

@keyframes skeleton-gradient {
  0% {
    background-position: 100% 50%
  }

  50% {
    background-position: 0 50%
  }

  100% {
    background-position: 100% 50%
  }
}

/******************************************************/
$header-height: $size-72;
$header-icon-height: $size-20;

$footer-height: $size-50;

$sidebar-width: $size-80;
$sidebar-opened-width: $size-265;

$content-padding: $size-46;
$content-mobile-padding: $size-30;
$content-max-width: $size-1248 + (2 * $content-padding);

.layout {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$header-height});
  max-height: calc(100vh - #{$header-height});
  overflow-y: scroll;
  scrollbar-gutter: stable;

  &.full-height {
    height: 100vh;
    max-height: 100vh;
  }
}

@supports (-moz-appearance: none) {
  .layout {
    overflow-y: auto;
  }
}

.not-logged-page {
  .layout {
    height: calc(100vh - #{$header-height} - #{$footer-height});
    max-height: calc(100vh - #{$header-height} - #{$footer-height});
  }
}

.layout--wrap {
  min-height: 100vh;
  transition: margin .15s linear;

  &.no-margin {
    margin-left: 0;
  }

  .backdrop-content {
    position: absolute;
    bottom: $size-20;
    left: $size-20;
    background-color: $dark-color;
    padding: $size-8 $size-12;
    border-radius: $size-6;
  }
}

.not-logged-page {
  .layout--wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0;
  }
}

.content--wrap {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$header-height});
  padding-bottom: $size-100;
}

.content {
  width: 100%;
  max-width: $content-max-width;
  padding: 0 $content-mobile-padding $content-mobile-padding $content-mobile-padding;
  margin: 0 auto;
  scroll-margin-top: $size-50;
}

/* used only to test content scroll*/
.content--scroll {
  height: 120vh;
}

.hamburger {
  z-index: $hamburger-z-index;
  cursor: pointer;
  position: absolute;
  top: $size-24;
  left: $content-mobile-padding;
}

.flex-column--gap-32 {
  display: block;
  width: 100%;
}

@media (min-width: $mobile-breakpoint-width) {
  .m-top-20-mobile {
    margin-top: 0;
  }

  .column-half {
    width: 50%;
  }

  .flex-column--gap-16 {
    display: flex;
    column-gap: $size-16;

    .column-half {
      width: calc(50% - #{$size-16} / 2);
    }
  }

  .flex-column--gap-32 {
    display: flex;
    column-gap: $size-32;

    .column-half {
      width: calc(50% - #{$size-32} / 2);
    }
  }

  .hamburger {
    display: none;
  }

  .layout--wrap {
    margin-left: $sidebar-width;
  }

  .content {
    padding: 0 $content-padding $content-padding $content-padding;
  }

  .content-horizontal-scroll {
    min-width: $content-max-width;
  }
}

@media (min-width: $sidebar-breakpoint-width) {
  .layout--wrap--opened {
    margin-left: $sidebar-opened-width;
  }
}

/******************************************************/
$slider-indicator-color: #F2F4F7;
$slider-indicator-active-color: $not-logged-light-color;

.indicator {
  cursor: pointer;
  text-align: center;
  margin: 0 $size-8;
  width: $size-10;
  height: $size-10;
  line-height: $size-10;
  border-radius: 50%;
  background-color: $slider-indicator-color;
  color: $slider-indicator-color;
}

.indicator.active {
  background-color: $slider-indicator-active-color;
  color: $slider-indicator-active-color;
}

.react-slideshow-container+ul.indicators {
  margin-top: $size-45;
}

/******************************************************/
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: $cover-spin-z-index;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: $size-20;
  bottom: $size-20;
  width: $size-40;
  height: $size-40;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: $size-4;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

$tooltip-bg-color: #101828;

.info-tooltip {
  +.MuiTooltip-popper {
    .MuiTooltip-tooltip {
      min-height: $size-35;
      background-color: $tooltip-bg-color;
      font-size: $size-12;
      font-family: $font-medium;
      display: flex;
      align-items: center;
      max-width: none;

      .MuiTooltip-arrow {
        color: $tooltip-bg-color !important;
      }
    }
  }

  &.nowrap {
    +.MuiTooltip-popper {
      .MuiTooltip-tooltip {
        white-space: nowrap;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.deactivated {
    +.MuiTooltip-popper {
      .MuiTooltip-tooltip {
        height: auto;
        max-width: $size-177;
      }
    }
  }

  &.w-max-314 {
    +.MuiTooltip-popper {
      .MuiTooltip-tooltip {
        max-width: $size-314;
      }
    }
  }
}

/******************************************************/
// OneTrust cookie banner
.ot-pc-footer-logo,
.ot-floating-button {
  display: none;
}

#onetrust-banner-sdk #onetrust-reject-all-handler {
  color: #231975 !important;
  border-color: #231975 !important;
  background-color: $white-color !important;
}

#onetrust-pc-sdk .ot-consent-hdr {
  max-width: inherit !important;
}

#onetrust-pc-sdk :not(.ot-hosts-ui) .ot-sel-all-chkbox {
  width: 30px !important;
  float: right !important;
  left: -20px !important;
}

#onetrust-pc-sdk :not(.ot-hosts-ui) .ot-sel-all-hdr {
  float: right !important;
  width: calc(100% - 68px) !important;
  padding-left: 16px !important;
}

#onetrust-pc-sdk .ot-fltr-opt {
  height: 10px !important;
}

#onetrust-pc-sdk #ot-fltr-modal button {
  padding: 12px 27px !important;
  margin-right: 2px !important;
}

.general-loading-icon {
  .loading-dots {
    width: auto;

    .loading-dots--dot {
      width: $size-10;
      height: $size-10;

    }
  }
}

.btn-no-bg {
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}