@import '../../../../styles/Reset';

$support-tab-inactive-color: #D0D5DD;
$reset-text-color: #344054;

.user-modal {
  padding: $size-6;
  min-height: $size-566;
  display: flex;
  flex-direction: column;

  .user-form__tab {
    cursor: pointer;
    border-bottom: $size-1 solid $support-tab-inactive-color;
    padding-right: $size-12;
    padding-bottom: $size-15;
    transition: border-bottom-color .3s ease-in-out;
  }

  .user-form__tab:first-of-type {
    padding-right: $size-18;
  }

  .user-form__tab--active {
    border-bottom: $size-1 solid $dark-color;
  }

  .profile-form {
    margin-top: $size-20;
    margin-bottom: $size-10;

    .title-firstname-lastname {
      margin-bottom: $size-20;
    }
  }

  .admin-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: $size-10;
    color: $dark-color;

    .doo-checkbox {
      padding-bottom: $size-6;
    }
  }

  .user-modal-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: $size-30;
    flex: 1;

    .column {
      width: fit-content;

      .column-title {
        margin-bottom: $size-18;
        color: $dark-color;
        white-space: nowrap;
      }

      .MuiFormGroup-root {
        row-gap: $size-8;

        .MuiFormControlLabel-label {
          font-family: $font;
          white-space: nowrap;
        }
      }
    }
  }

  .tab-description {
    .assigned-templates-container {
      flex: 1;
      margin-bottom: $size-10;

      .assigned-templates {
        flex: 1;
        max-height: $size-260;
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    button {
      width: auto;
      line-height: $size-13;
    }
  }

  .reset-password-question {
    p {
      color: $reset-text-color;
    }

    button {
      background-color: transparent;
      color: $reset-text-color;
      box-shadow: none;
      font-family: $font;
      width: auto;
      border-bottom: $size-1 solid;
      padding: 0;
      height: auto;
      line-height: $size-20;
      border-radius: 0;
    }
  }
}

.workflow-detail {
  padding: $size-6 0;

  &-title {
    margin-bottom: $size-24;
    padding: 0 $size-10;
  }

  &-accordions {
    height: $size-454;
    padding: 0 $size-10;
    margin-bottom: $size-16;
  }

  &-btn {
    display: flex;
    justify-content: flex-end;
    padding: 0 $size-10;
  }
}