@import '../../../styles/Reset';

@mixin flex-items-center {
  display: flex;
  align-items: center;
}

.table-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: $size-16;

  .tableTopInfo {
    padding: 0;
  }

  .table-top-actions {
    @include flex-items-center;
    margin-top: $size-18;

    #step-2 {
      display: flex;
      margin-right: $size-25;
    }

    .actions-search {
      @include flex-items-center;
      position: relative;
      border-radius: $size-4;
      padding: $size-6 $size-13;
      border: $size-1 solid $table-row-border-color;

      &.active {
        border-color: $dark-color;
      }

      &.applied {
        border-color: #FFA117;
      }

      .actions-search-input,
      .row-inputWrapper {
        input {
          border: none;
          outline: none;
          color: $light-color;
          font-size: $size-14;
          line-height: $size-22;
          width: $size-183;

          &::placeholder {
            color: $light-color;
          }
        }
      }

      .actions-search-resetIcon {
        padding: 0 $size-12;
        background-color: transparent;
        border-right: $size-1 solid $table-row-border-color;
        height: $size-18;
        min-width: $size-39;
        @include flex-items-center;

        svg {
          width: $size-9;
          height: $size-9;
        }
      }

      .actions-search-searchIcon {
        padding: 0 $size-12;
        background-color: transparent;
        border-right: $size-1 solid $table-row-border-color;
        height: $size-18;
        @include flex-items-center;
      }

      .actions-search-filterOptionIcon {
        background-color: transparent;
        margin: 0 $size-4 0 $size-10;
        width: $size-22;
        height: $size-22;
        border-radius: $size-4;
        @include flex-items-center;
        justify-content: center;

        &.active {
          background-color: $dark-color;

          path {
            fill: $white-color;
          }
        }
      }
    }

    .actions-filter {
      position: relative;

      .actions-filter-btn {
        background-color: transparent;
        border: $size-1 solid $table-row-border-color;
        border-radius: $size-4;
        padding: $size-9 $size-15;
        @include flex-items-center;
        height: $size-36;

        .actions-filter-btn-text {
          margin-left: $size-7;
          color: $light-color;
        }

        &.active {
          border-color: $dark-color;
        }

        &.applied {
          border-color: #FFA117;
        }
      }

      .actions-filter-btn-warning {
        @include flex-items-center;
        justify-content: center;
        position: absolute;
        top: (-$size-10);
        right: (-$size-10);
        width: $size-20;
        height: $size-20;
        border-radius: 50%;
        border: $size-2 solid $white-color;
      }

    }

    .actions-button {
      margin-left: $size-25;

      button {
        @include flex-items-center;
        padding: $size-10 $size-16;
        font-size: $size-14;
        line-height: $size-18;
        white-space: nowrap;

        svg {
          margin-right: $size-8;

          path {
            stroke: $white-color;
          }
        }
      }
    }
  }
}

@media (max-width: $desktop-breakpoint-width) {
  .table-top {
    flex-direction: column;
    align-items: flex-start;
  }
}