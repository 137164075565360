@import '../../styles/Reset';

.footer--wrap {
  margin-top: auto;
  width: 100%;
  height: $footer-height;
  min-height: $footer-height;
  text-align: center;

  .footer {
    .footer-link__separator {
      position: relative;
      top: $size-4;
      font-size: $size-22;
      margin: 0 $size-5;
    }

    .footer-link__separator:before {
      content: '\00B7';
    }

    .rate-us-container {
      display: inline-block;
    }
  }
}
