@import '../../styles/Reset';

$progress-bar-bg-color: #c4c4c4;
$order-status-active: #231975;

@keyframes shakeX {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  20%,
  60% {
    transform: translate3d((-$size-5), 0, 0);
  }

  40%,
  80% {
    transform: translate3d($size-5, 0, 0);
  }
}

@keyframes slideInLeft100 {
  from {
    left: -100%;
  }

  to {
    left: 0;
  }
}

@keyframes slideInLeft50 {
  from {
    left: -50%;
  }

  to {
    left: 0;
  }
}

svg.animate__shakeX {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: shakeX;
}

.payment-success-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .page-icon-container {
    .loader-icon {
      width: $size-83;
      height: $size-83;
      margin-bottom: $size-34;
      margin-top: $size-16;
    }

    .lottie-animation {
      height: $size-120;
      width: $size-120;
      margin: 0 auto $size-18;
    }
  }

  .title-container {
    margin-bottom: $size-26;
  }

  .subtitle-container {
    max-width: $size-420;
    margin: 0 auto $size-35;
  }

  .button-container {
    margin: 0 auto;
  }
}
