@import '../../../../styles/Reset';

.new-order-page {
  .test-order-information {
    max-width: $size-500;
    margin: $size-100 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .test-order-information-title {
      font-size: $size-20;
      margin-bottom: $size-20;
      display: flex;
      align-items: center;
      gap: $size-10;
    }

    .test-order-information-text {
      text-align: center;
      margin-bottom: $size-20;
    }
  }

  .section-images-upload {
    margin: $size-28 0 $size-78;

    .section-tabs {
      display: flex;

      .section-tab {
        padding: $size-10 $size-28;
        background-color: $white-color;
        color: $dark-color;

        &.active {
          background-color: $table-gray-color;
        }
      }
    }

    .images-upload-content {
      background-color: $table-gray-color;
      padding: $size-15 0;
      position: relative;

      &.upload-img-wrapper {
        min-height: $size-170;
        position: relative;

        &.fill {
          min-height: $size-350;
        }

        .upload-images {
          padding: $size-5 $size-24 $size-44;
          position: relative;
          z-index: 1;

          &.enabled-select-test-image {
            li {
              &:hover {
                background-color: $light-blue-color;
                cursor: pointer;
              }

              .image-row {
                cursor: pointer;
              }
            }
          }

          li {
            border-top: $size-2 solid $table-row-border-color;
            padding: 0 $size-4;
            display: flex;
            align-items: center;
            cursor: default;

            &:hover {
              background-color: $table-row-border-color;

              .upload-img-checkIcon {
                display: none;
              }

              .upload-img-binIcon {
                display: flex;
              }
            }

            .image-row {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              background-color: transparent;
              padding: $size-4 0;
              cursor: default;
              color: $dark-color;

              .image-row-badge {
                background-color: $dark-color;
                color: $white-color;
                border-radius: $size-4;
                display: flex;
                align-items: center;
                padding: 0 $size-4;
                gap: $size-4;
                margin-left: $size-6;
                cursor: default;

                p {
                  line-height: $size-10;
                }

                .badge-delete {
                  background-color: transparent;

                  svg {
                    width: $size-8;
                    height: $size-8;

                    path {
                      fill: $white-color;
                    }
                  }
                }

              }
            }

            .upload-img-binIcon {
              align-items: center;
              margin-right: $size-4;
              display: none;
              background-color: transparent;

              svg {
                fill: $switch-active-color;
              }
            }

            .upload-img-checkIcon {
              display: flex;
              align-items: center;
              margin-right: $size-4;
              background-color: transparent;

              svg path {
                fill: green;
              }
            }

            .upload-img-progressWrapper {
              width: $size-20;
              height: $size-5;
              background-color: $white-color;
              border-radius: $size-3;
              margin-right: $size-8;

              .upload-img-progress {
                height: $size-5;
                background-color: $input-focus-color;
                border-radius: $size-3;
              }
            }
          }
        }

        .img-upload-input-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
          background-color: #00000055;
          backdrop-filter: blur(5px);
          border-radius: $size-8;

          .img-upload-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            color: $white-color;
          }

          .img-upload-input {
            width: 100%;
            height: 100%;
            opacity: 0;
            position: relative;
            z-index: 2;
          }
        }

        .upload-top {
          display: flex;
          justify-content: space-between;
          margin: $size-20 $size-24 $size-32 $size-24;

          .upload-top-left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            z-index: 1;
            gap: $size-4;

            button {
              background-color: transparent;
              display: flex;
              align-items: center;
              gap: $size-3;
              padding: $size-2 $size-6;

              p {
                color: $dark-color;
              }

              &.active {
                background-color: $light-blue-color;
                border-radius: $size-3;
              }

              svg {
                width: $size-16;
                height: $size-16;

                path {
                  fill: $dark-color;
                }
              }
            }
          }

          .upload-top-addBtn button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $size-8;
            padding: $size-9 $size-14;
            background-color: $input-color;
            transition: 0.3s;
            position: absolute;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            top: $size-32;

            &:hover {
              opacity: 0.8;
            }
          }

          .upload-top-right {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;

            button {
              background-color: transparent;

              &.rot-180 {
                svg {
                  transform: rotate(-180deg);
                }
              }

              svg {
                transition: 0.3s;

                path {
                  fill: $input-color;
                }
              }

            }
          }
        }

        .upload-input-text {
          padding: $size-50 $size-24 $size-80 $size-24;
          color: $dark-color;
          opacity: 0.2;
          text-align: center;
          position: relative;
        }

        .upload-warning-text {
          padding: $size-24 $size-30;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }

    .images-upload-content {
      .content-descriptions {
        padding: $size-8 $size-32;
        color: $dark-color;

        .descriptions-title {
          margin-bottom: $size-6;
        }

        .descriptions-text {
          margin-bottom: $size-20;
        }

        .csv-remove-all-images {
          display: flex;
          align-items: center;
          gap: $size-3;
          margin-bottom: $size-20;
          color: $switch-active-color;
          background-color: transparent;

          svg {
            width: $size-14;
            height: $size-14;

            path {
              fill: $switch-active-color;
            }
          }
        }

        .descriptions-textarea {
          .textarea--wrap {
            width: 100%;
          }
        }

        .csv-images {
          padding: $size-14 0 0 0;

          ul {
            padding: $size-8 0;
            display: grid;
            gap: $size-8;

            li {
              display: flex;
              align-items: center;
              gap: $size-4;

              button {
                background-color: transparent;
                display: flex;
                align-items: center;

                svg {
                  width: $size-12;
                  height: $size-12;
                }
              }
            }
          }
        }

        .folder-item {
          display: flex;
          align-items: center;
          margin-bottom: $size-4;
          gap: $size-4;

          &.disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
}