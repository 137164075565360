@import '../../styles/Reset';

$sidebar-color: #231975;
$sidebar-bg-color: #E6F6FF;

$sidebar-bg-hover-color: #CCEFFF;

$sidebar-active-color: #FFFFFF;
$sidebar-active-bg-color: #447AF0;

$sidebar-menu-item-size: $size-45;

$sidebar-shadow: $size-5 0 $size-12 rgba(0, 0, 0, .25);

@mixin sidebar-logo {
  width: $size-122;
  height: $size-34;
  margin-left: $size-66;
  background: url("../../svg/logo.svg") center center/cover no-repeat;
  transition: all 0.1s ease-in-out;
}

@mixin sidebar-logo-icon {
  cursor: pointer;
  width: $size-31;
  height: $size-36;
  margin-left: $size-25;
  background: url("../../svg/logo_icon.svg") center center/cover no-repeat;
}

@mixin menu-item-label-show {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

@mixin menu-item--label-hide {
  opacity: 0;
  transition: opacity 0s ease-in-out;
}

@mixin sidebar-collapse-show {
  opacity: 1;
  transition: opacity .3s ease-in-out, background-color .5s ease-in-out, background-image .5s ease-in-out;
}

@mixin sidebar-collapse-hide {
  opacity: 0;
  transition: opacity 0s ease-in-out;
}

.sidebar--wrap {
  display: none;
  /* don't show on mobile devices */
  z-index: $sidebar-z-index;
  position: absolute;
  top: 0;
  left: 0;
  transition: width .15s ease-in-out;
  width: $sidebar-width;
  height: 100vh;
  padding: $size-10 0;
  color: $sidebar-color;
  background-color: $sidebar-bg-color;

  .sidebar {
    padding: $size-10 0;
  }

  .sidebar__logo--wrap {
    height: $size-34;
  }

  .sidebar__logo {
    @include sidebar-logo-icon;
  }

  .sidebar__collapse {
    cursor: pointer;
    position: absolute;
    top: $size-26;
    right: $size-13;
    width: 0;
    height: 0;
    border-radius: $size-4;
    text-align: center;
    @include menu-item--label-hide;
  }

  .sidebar__menu {
    margin-top: $size-27;
    padding: 0 $size-17 0 $size-18;
  }

  .sidebar__menu-item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    height: $sidebar-menu-item-size;
    width: $sidebar-menu-item-size;
    text-align: left;
    border-radius: $size-6;
    margin-bottom: $size-2;

    &:hover {
      background-color: $sidebar-bg-hover-color;
    }
  }

  #sidebar-menu-item-back_to_my_user,
  #sidebar-menu-item-back_to_my_user:hover {
    background-color: #C65054;
    color: $sidebar-active-color;
  }

  .sidebar__menu-item--active,
  .sidebar__menu-item--active:hover {
    color: $sidebar-active-color;
    background-color: $sidebar-active-bg-color;
  }

  .sidebar__menu-item p {
    padding-left: $size-48;
    line-height: $sidebar-menu-item-size;
    overflow: hidden;
    @include menu-item--label-hide;
  }

  .sidebar__menu-item svg {
    position: absolute;
    top: $size-14;
    left: $size-13;
  }

  .sidebar__menu-item svg.top-10 {
    top: $size-10;
  }

  .sidebar__menu-item svg.top-11 {
    top: $size-11;
  }

  .sidebar__menu-item svg.top-12 {
    top: $size-12;
  }

  .sidebar__menu-item svg.top-13 {
    top: $size-13;
  }

  .sidebar__menu-item svg.left-10 {
    left: $size-10;
  }

  .sidebar__menu-item svg.left-11 {
    left: $size-11;
  }

  .sidebar__menu-item svg.left-14 {
    left: $size-14;
  }

  .sidebar__menu-item svg.left-15 {
    left: $size-15;
  }

  &.hovered {
    width: $sidebar-opened-width;
    box-shadow: $sidebar-shadow;

    .sidebar__logo {
      @include sidebar-logo;
    }

    .sidebar__menu-item {
      width: inherit;
    }

    .sidebar__menu-item p {
      @include menu-item-label-show;
    }
  }
}

.sidebar--wrap--opened {
  width: $sidebar-opened-width;
}

.sidebar--wrap--mobile {
  display: inherit;
  width: $sidebar-opened-width;
  box-shadow: $sidebar-shadow;

  .sidebar__logo {
    @include sidebar-logo;
  }

  .sidebar__menu-item {
    width: inherit;
  }

  .sidebar__menu-item p {
    @include menu-item-label-show;
  }
}

@media (min-width: $mobile-breakpoint-width) {
  .sidebar--wrap {
    display: inherit;
  }

  .sidebar--wrap--opened {
    width: $sidebar-width;

    &:hover {
      width: $sidebar-opened-width;
    }
  }
}

@media (max-width: $sidebar-breakpoint-width) {
  #sidebar-menu-item-tooltip {
    display: none;
  }
}

@media (min-width: $sidebar-breakpoint-width) {

  .sidebar--wrap,
  .sidebar--wrap:hover {
    width: $sidebar-width;
    box-shadow: none;

    .sidebar__logo {
      @include sidebar-logo-icon;
    }

    .sidebar__menu-item {
      width: $sidebar-menu-item-size;
    }

    .sidebar__menu-item p {
      @include menu-item--label-hide;
    }
  }

  .sidebar--wrap:hover {
    .sidebar__logo {
      opacity: 0;
    }

    .sidebar__collapse {
      @include sidebar-collapse-show;
      width: $size-23;
      height: $size-23;
      right: $size-27;
      background: url("../../svg/collapse_open.svg") center center/$size-16 $size-13 no-repeat;

      &:hover {
        background: #447AF0 url("../../svg/collapse_open_white.svg") center center/$size-16 $size-13 no-repeat;
      }
    }
  }

  .sidebar--wrap--opened,
  .sidebar--wrap--opened:hover {
    width: $sidebar-opened-width;

    .sidebar__logo {
      opacity: 1;
      @include sidebar-logo;
    }

    .sidebar__menu-item {
      width: inherit;
    }

    .sidebar__menu-item p {
      @include sidebar-collapse-show;
    }
  }

  .sidebar--wrap--opened:hover {
    .sidebar__collapse {
      @include sidebar-collapse-show;
      width: $size-23;
      height: $size-23;
      right: $size-13;
      background: url("../../svg/collapse.svg") center center/$size-16 $size-13 no-repeat;

      &:hover {
        background: #447AF0 url("../../svg/collapse_white.svg") center center/$size-16 $size-13 no-repeat;
      }
    }
  }
}