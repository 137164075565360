@import '../../../styles/Reset';

.public-gallery-users {
  .tableTopInfo {
    padding: 0 0 0 $size-16;
  }

  .template-chips-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: $size-4;

    &.allGalleryChips-modal-content {
      flex-wrap: wrap;
      gap: $size-8;
      margin-top: $size-18;
    }

    button {
      background-color: transparent;
    }

    .template-chip {
      border: $size-2 solid $dark-color;
      height: $size-22;
      cursor: pointer;

      span {
        color: $light-color;
        font-size: $size-12;
        font-family: $font-medium;
        padding-left: $size-8;
        padding-right: $size-8;
      }
    }
  }

  .add-button-container {
    display: flex;
    justify-content: flex-end;

    .button {
      width: auto;
      white-space: nowrap;
      border-radius: $size-7;

      .add-button-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: $size-8;

        p {
          font-size: $size-14;
          color: $dark-color;
          font-family: $font-semibold;
        }
      }
    }
  }

  .table-wrapper {
    margin-top: $size-32;

    .table-row {
      &:last-child {
        .more-actions-content {
          top: (-$size-10);
        }
      }
    }

    .more-actions {
      position: relative;
      display: flex;
      justify-content: flex-end;
      padding-top: $size-7;

      &-icon {
        background-color: transparent;
      }

      &-content {
        position: absolute;
        right: $size-42;
        top: 50%;
        transform: translateY(-50%);
        background-color: $white-color;
        padding: $size-13 $size-13 $size-7;
        border-radius: $size-8;
        border: $size-1 solid $table-row-border-color;
        box-shadow: 0 $size-4 $size-8 rgba(0, 0, 0, 0.05);

        &-row {
          background-color: transparent;
          display: flex;
          align-items: center;
          gap: $size-10;
          white-space: nowrap;
          color: $dark-color;
          padding: $size-6 $size-12;
          border-radius: $size-8;
          width: 100%;
          height: $size-36;

          svg {
            width: $size-22;
            height: $size-22;
          }

          &:hover {
            background-color: $box-menu-item-bg-hover-color;
          }
        }
      }
    }
  }

  .allGalleryChips-modal {
    .modal {
      padding: $size-32;
    }
  }
}