@import '../../styles/Reset';

.doo-checkbox {
  .MuiSvgIcon-root {
    color: $input-label-color;
  }

  & input[type="checkbox"]:focus {
    &+svg {
      box-shadow: 0 0 0 $size-1 $checkbox-focus-color;
      border: $size-1 solid $checkbox-focus-color;
      border-radius: $size-4;

      rect {
        stroke: none;
      }
    }
  }
}

.doo-checkbox.checkbox-error {
  & input[type="checkbox"] {
    &+svg {
      box-shadow: 0 0 0 $size-1 $input-error-color;
      border: $size-1 solid $input-error-color;
      border-radius: $size-4;
    }
  }
}

.not-logged-page {
  .doo-checkbox {
    .MuiSvgIcon-root {
      color: $not-logged-input-label-color;
    }
  }
}