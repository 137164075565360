@import '../../../../styles/Reset';

.section-remark {

  .section-content {
    padding: $size-16 $size-22;

    textarea {

      &::placeholder {
        text-align: left;
      }

    }

  }

}