@import "../../../../styles/Reset";

.order-details-info {
  margin-bottom: $size-5;

  .order-details-info-top {
    padding: $size-10 $size-22;
    background-color: $table-row-border-color;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dark-color;

    .info-top-summary {
      display: flex;
      justify-content: flex-end;
      gap: $size-24;
      color: $switch-color;
      max-width: $size-420;

      p {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      .info-top-summary-arrow {
        display: flex;
        align-items: center;

        svg path {
          stroke: $dark-color;
        }
      }
    }
  }

  .order-details-info-content {
    border: $size-1 solid $table-row-border-color;
    padding: $size-14 $size-12;
    display: grid;
    gap: $size-15;

    .content-row {
      display: flex;
      flex-wrap: wrap;
      gap: $size-20 $size-32;

      .content-row-column {

        &.quarter {
          width: $size-144;
        }

        .content-row-column-title {
          margin-bottom: $size-2;
          display: flex;
          gap: $size-3;
          color: $dark-color;
        }

        .content-row-column-list {
          ul {
            padding-left: $size-14;
            font-size: $size-14;
            line-height: $size-18;
            list-style: disc;

            li::marker {
              font-size: $size-8;
            }

            .list-item {
              display: flex;
              align-items: center;
              gap: $size-5;
              color: $dark-color;

              .list-item-bgBox {
                width: $size-12;
                height: $size-12;
                border-radius: $size-3;
                border: $size-1 solid $table-row-border-color;
              }
            }
          }
        }

      }

    }

  }
}