@import '../../../../styles/Reset';

.new-order-page {
  .top-info {
    display: flex;
    gap: $size-24;
    margin-top: $size-37;
    margin-bottom: $size-33;

    .top-info-box {
      display: flex;
      flex-direction: column;
      border-radius: $size-8;
      width: 100%;
      padding: $size-24;
      border: $size-1 solid $table-row-border-color;
      color: $dark-color;
      gap: $size-24;

      .info-box-input {
        padding: $size-8 $size-10;
        width: 100%;
        height: $size-36;
        font-size: $size-14;
        line-height: $size-20;
        font-family: $font-medium;
        color: $dark-color;
        border: $size-1 solid $textarea-border-color;
        border-radius: $size-3;
        outline: none;

        &::placeholder {
          color: $new-order-name-input-placeholder-color;
        }

        &:focus-visible {
          border: $size-1 solid $textarea-focus-color;
          box-shadow: none;
        }
      }

      .info-box-turnaround {
        height: $size-36;
        display: flex;
        align-content: center;

        .MuiFormGroup-root {
          gap: $size-10;
        }

        .doo-label .MuiFormControlLabel-label {
          margin-right: 0;
        }

        .MuiTypography-root {
          display: flex;
          align-items: center;
        }

        .price-badge {
          margin: 0 $size-4;
        }
      }

      .turnaround_text {
        span {
          font-family: $font-medium;
        }
      }

      .workflows-tab {
        cursor: pointer;
        padding: $size-10;
        border-radius: $size-3;

        &.active {
          background-color: #EAECF0;
        }
      }

      .top-info-box--workflows {
        display: flex;
        gap: $size-20;

        .MuiOutlinedInput-input {
          font-size: $size-16;
          line-height: $size-22;
          font-family: $font-semibold;
        }

        div {
          min-width: $size-300;
        }

        .doo-label .MuiFormControlLabel-label {
          margin-right: 0;
          line-height: 2;
        }
      }
    }
  }
}