@import '../../../styles/Reset';

.public-gallery-settings {
  &-section {
    .name-row {
      display: flex;
      align-items: center;
      gap: $size-40;
      margin-bottom: $size-32;

      label p {
        font-size: $size-16;
        color: $dark-color;
        font-family: $font-semibold;
      }

      .input--wrap {
        width: 100%;
      }
    }

    .detail-row {
      display: flex;
      justify-content: space-between;
      gap: $size-32;

      &-column {
        display: flex;
        flex-direction: column;
        gap: $size-16;

        .column-input {
          display: flex;
          align-items: center;
          gap: $size-32;

          label p {
            font-size: $size-16;
            color: $dark-color;
            font-family: $font-semibold;
          }

          .input--wrap {
            width: 100%;
          }

          .column-input-icons {
            display: flex;
            align-items: center;
            gap: $size-8;

            .password-input-wrapper {
              position: relative;

              button {
                position: absolute;
                background-color: transparent;
                bottom: (-$size-28);
                left: $size-12;

                p {
                  font-size: $size-14;
                  color: $dark-color-opacity-40;
                  font-family: $font-medium;
                }
              }
            }
          }

          .column-input-buttons {
            display: flex;
            gap: $size-4;

            .copy-button,
            .new-tab-button {
              background-color: transparent;

              &:hover {
                border-bottom: none;
              }

              svg {
                width: $size-20;
                height: $size-20;
              }
            }
          }
        }

        &.multiple-inputs {
          .column-input {
            gap: $size-8;

            label p {
              width: $size-105;
            }
          }
        }

        &.radio-group {
          .MuiFormGroup-root {
            gap: $size-12;

            .MuiFormControlLabel-root {
              padding: $size-10 0;
            }
          }

        }

      }
    }

  }
}