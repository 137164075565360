@import '../../styles/Reset';

$link-color: #447AF0;

.workflows-page {
  .template-link {
    a {
      border-bottom: $size-1 solid;
    }

    a:hover {
      text-decoration: none;
    }
  }

  .table-cursor-default {
    .table-row {
      cursor: default;
    }
  }

  .tableTopInfo {
    max-width: $size-530;

    .table-top-icon {
      margin-right: $size-12;
    }
  }

  #workflow-table {
    .table-row {
      &:nth-last-child(2) {
        .more-actions-content {
          top: (-$size-10);
        }
      }

      &:nth-child(1) {
        .more-actions-content {
          top: $size-10;
        }
      }
    }
  }

  .table-row {
    cursor: pointer;

    &.open {
      .more-actions {
        &-icon {
          svg {
            path {
              fill: $white-color;
            }
          }
        }
      }
    }
  }

  .MuiTableCell-head,
  .table-row-cell {
    padding: $size-11 $size-10 !important;

    &:first-child {
      padding-left: $size-20 !important;
    }

    .support-link {
      color: $dark-color;
      border-bottom: $size-1 solid $dark-color;
    }
  }

  .table-row-cell-buttons {
    .icon-dark-color {
      path {
        fill: $dark-color;
      }
    }

    .info-icon {
      width: $size-20;
      height: $size-20;
    }

    &.filefront {
      .filefront-icon {
        width: $size-17;
        height: $size-17;
        background-color: $filefront-gray-color;
        border-radius: 50%;
        margin-left: $size-17;
        margin-top: $size-1;

        &.active {
          background-color: $success-500-color;
        }
      }
    }
  }

  .more-actions {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-right: $size-14;
    padding-top: $size-7;

    &-icon {
      background-color: transparent;
    }

    &-content {
      position: absolute;
      z-index: 1;
      right: $size-42;
      top: 50%;
      transform: translateY(-50%);
      background-color: $white-color;
      padding: $size-13 $size-13 $size-7;
      border-radius: $size-8;
      border: $size-1 solid $table-row-border-color;
      box-shadow: 0 $size-4 $size-8 rgba(0, 0, 0, 0.05);

      &-icon {
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: $size-10;
        white-space: nowrap;
        color: $dark-color;
        padding: $size-6 $size-12;
        border-radius: $size-8;
        width: 100%;
        height: $size-36;

        &.copy-icon {
          path {
            fill: $dark-color;
          }
        }

        &.setting-icon {
          svg {
            width: $size-18;
            height: $size-18;
          }
        }

        &.add-icon {
          svg {
            width: $size-20;
            height: $size-20;
          }
        }

        &.view-icon {
          svg {
            width: $size-20;
            height: $size-20;
          }
        }

        svg {
          width: $size-22;
          height: $size-22;
        }

        &:hover {
          background-color: $box-menu-item-bg-hover-color;
        }
      }
    }
  }

  .no-templates {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    padding-top: $size-200;

    .doopic-logo {
      width: $size-30;
      margin-bottom: $size-14;
    }

    .no-templates-desc {
      width: $size-462;
      margin-top: $size-30;
      text-align: center;
    }
  }
}