@import '../../styles/Reset';

.doo-select--wrap {
  .MuiOutlinedInput-root {
    text-align: left;
    height: $size-40;
    top: (-$size-4);
  }

  .MuiOutlinedInput-root.Mui-error {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border: $size-1 solid $input-error-color;
      }
    }
    
    .MuiOutlinedInput-notchedOutline {
      border: $size-1 solid $input-error-color;
      box-shadow: 0 0 0 $size-1 $input-error-color;
    }
  }
  
  .MuiOutlinedInput-input {
    font-size: $size-14;
    color: $input-color;
    padding-top: $size-20;
  }

  .MuiOutlinedInput-notchedOutline {
    border: $size-1 solid $input-border-color;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: $size-1 solid $input-border-color;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    box-shadow: 0 0 0 $size-1 $input-focus-color;
    border: $size-1 solid $input-focus-color;
  }

  .MuiSelect-icon {
    top: $size-20;
    right: $size-13;
  }

  .select--placeholder {
    font-size: $size-14;
    color: $input-placeholder-color;
  }
}

.MuiMenuItem-root {
  font-size: $size-14 !important;
  color: $not-logged-light-color !important;
}

.not-logged-page {
  .MuiOutlinedInput-input {
    color: $not-logged-input-label-color;
  }
}
