@import '../../styles/Reset';

.reset-page {
  display: flex;
  height: 100vh;
  min-height: $size-700;

  .loader-icon {
    width: $size-39;
    height: $size-39;
    margin: 0 auto $size-37 auto;
  }

  .lottie-animation {
    width: $size-56;
    height: $size-56;
    margin: 0 auto $size-24 auto;
  }

  .reset-form--wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .reset-form__line {
    width: $size-360;
  }

  .back-arrow {
    position: relative;
    top: $size-1;
    height: $size-12;
    margin-right: $size-3;
  }
}