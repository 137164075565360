@import '../../../../styles/Reset';

.new-order-page {
  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .format-options {
      display: flex;
      align-items: flex-end;

      .format-tabBtn-wrapper {
        position: relative;
        display: flex;
        align-items: flex-end;

        .format-tabBtn {
          background-color: $table-row-border-color;
          border: $size-1 solid $white-color;
          padding: $size-9 $size-22;
          color: $dark-color;

          &.active {
            background-color: $table-gray-color;
          }
        }

        .format-tabBtn-close {
          position: absolute;
          top: (-$size-3);
          right: (-$size-3);
          z-index: 1;
          background-color: transparent;

          svg {
            width: $size-12;
            height: $size-12;
          }
        }

      }

      .format-new-btn {
        padding: $size-8;
        height: $size-38;
        margin-left: $size-2;
        background-color: $table-row-border-color;
        border-radius: $size-4;
        display: flex;
        align-items: center;

        svg {
          width: $size-20;
          height: $size-20;
        }

        &[disabled] {
          opacity: 0.5;
          cursor: default;
        }
      }

      .info-modal-icon {
        padding: $size-8;
      }
    }

    .reset-button {
      background-color: transparent;
      display: flex;
      align-items: center;
      gap: $size-2;
      color: $switch-active-line-color;

      svg path {
        fill: $switch-active-line-color;
      }
    }
  }
}