@import '../../styles/Reset';

$button-bg-color: #E0E0E0;
$button-text-color: #667085;
$border-color: #EAECF0;
$link-color: #447AF0;
$warning-color: #C65054;

.api-order-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .box {
    width: 100%;
    border: $size-1 solid $border-color;
    border-radius: $size-8;
    padding: $size-41 $size-24;
    margin-bottom: $size-21;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;
      gap: $size-20;

      .inline {
        display: inline;
      }

      .warning-message {
        color: $warning-color;
      }

      .folder-item {
        display: flex;
        align-items: center;
        margin-bottom: $size-6;

        &:last-child {
          margin-bottom: 0;
        }

        .doo-checkbox {
          padding: 0;
          margin-right: $size-13;
        }

        .folder-icon {
          margin-right: $size-13;
        }

        .a {
          color: $link-color;
        }
      }
    }

    .right {
      padding-top: $size-20;

      .button {
        width: $size-74;
        padding: 0;
      }

      .refresh-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        .refresh-icon {
          margin-right: $size-8;
        }
      }
    }
  }

  .create-order-btn {
    button {
      min-width: $size-256;
      line-height: $size-13;

      &:disabled {
        &:hover {
          background-color: $white-color;
        }
      }
    }
  }

  button {
    background-color: $button-bg-color;
    color: $button-text-color;

    &:hover {
      background-color: darken($button-bg-color, 10%);
    }
  }

  .image-count--wrap {
    margin: 0 $size-10;
    padding: 0 $size-6;
    background-color: #eee;
    border-radius: $size-2;
  }
}