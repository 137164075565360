@import '../../../../styles/Reset';

.internal-remark {
  border: $size-1 solid $input-border-color;
  padding: $size-18 $size-24;
  border-radius: $size-8;
  margin: $size-32 0;

  .internal-remark-title {
    margin-bottom: $size-10;
  }

  .internal-remark-inputs {
    display: grid;
    gap: $size-12;

    .input--wrap {
      width: 100%;
    }

    textarea,
    input {
      width: 100%;
      height: $size-36;
      padding: $size-6 $size-12;
      resize: vertical;
      transition: 0.3s;
    }

    textarea:focus {
      height: $size-100;
    }
  }
}