@import '../../styles/Reset';

$outstanding-border-color: #EAECF0;
$outstanding-text-color: #231975;

.header {
  display: flex;
  height: $header-height;

  .header--wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: $content-max-width;
    padding: 0 $content-mobile-padding 0 $size-105;
    margin: 0 auto;

    .outstanding-balance-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: center;
      flex-wrap: nowrap;
      padding: $size-7 $size-6 $size-7 0;
      border: $size-1 solid $outstanding-border-color;
      border-radius: $size-7;
      max-height: $size-47;
      margin-left: (-$size-80);

      .outstanding-balance-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: $size-125;

        p {
          color: $dark-color;
        }
      }

      .button {
        height: $size-33;
        line-height: $size-14;
        font-size: $size-15;
        padding: 0 $size-18;
        border-radius: $size-5;
      }
    }
  }

  .header--title {
    padding-top: $size-19;
    padding-left: $size-12;
    width: 50%;
  }

  .header--menu--wrap {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 50%;
  }

  .header--menu--wrap > div {
    position: relative;
    width: $size-22;
    margin-left: $size-19;
  }

  .header--menu--wrap > div.avatar-logout--wrap {
    width: $size-54;
  }

  @media (min-width: $mobile-breakpoint-width) {
      .header--wrap {
        padding: 0 $content-padding;
      }
  }
}

.header--shadow {
  position: relative;
  box-shadow: 0 $size-4 $size-11 rgba(0, 0, 0, 0.25);
  z-index: $header-z-index;
}
