@import '../../styles/Reset';

.header-logo--wrap {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;

  .header-logo {
    cursor: pointer;
    text-align: left;
  }

  .header-logo--icon {
    width: $size-145;
    margin-left: $size-32;
    margin-top: $size-24;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
