@import '../../styles/Reset';

.switch {
  .MuiButtonBase-root {
    &.Mui-checked {
      color: $switch-active-dot-color;

      &+.MuiSwitch-track {
        background-color: $switch-active-line-color;
        opacity: 1;
      }
    }

    .MuiSwitch-thumb {
      box-shadow: (0 $size-1 $size-4 rgba(0, 0, 0, 0.25));
    }
  }

  &.white {
    .MuiButtonBase-root {
      &.Mui-checked {
        &+.MuiSwitch-track {
          background-color: $white-color;
          opacity: 1;
        }
      }
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: default;
  }
}