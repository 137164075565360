@import '../../styles/Reset';

.table-container {

  &.table-scroll {
    max-height: $size-400;
    overflow-y: auto;
  }

}

.table {
  width: calc(100% - #{$size-1}) !important;

  .table-head {

    &.table-head-fixed {
      position: sticky;
      top: 0;
      background: $white-color;
    }

    .table-head-cell {
      padding: $size-14 $size-24;

      /* Shows if "word" is bigger than fixed column width. Without this "horizontal scrolling" appears */
      word-break: $break-word;

      &.table-head-cell-label {
        color: $dark-color;

        .MuiTableSortLabel-icon {
          margin-left: $size-8;
        }
      }

      &.table-head-cell-hide {
        opacity: 0;
        height: 0;
        max-height: 0;
        padding: 0;
        margin: 0;
        border: none;
        font-size: 0 !important;
        line-height: 0 !important;

        span {
          font-size: 0 !important;
          line-height: 0 !important;
        }
      }
    }
  }

  .table-row {

    .table-row-cell {
      border-bottom: $size-1 solid $table-row-border-color;

      .table-row-cell-download,
      .table-row-cell-add {
        background-color: transparent;
      }

      .table-row-cell-buttons {
        display: flex;
        align-items: center;
        gap: $size-24;

        .table-row-cell-buttons-delete,
        .table-row-cell-buttons-edit {
          background-color: transparent;
        }
      }

      span {
        color: $dark-color;

        &.table-row-cell-debtBox {
          font-size: $size-12;
          line-height: $size-18;
          padding: $size-2 $size-8 $size-2 $size-8;
          text-transform: capitalize;
          border-radius: $size-16;
          display: inline-flex;
          align-items: center;

          svg {
            width: $size-14;
            height: $size-14;
            margin-right: $size-4;
          }

          &.paid {
            background-color: $debtBox-paid-bg-color;

            span {
              color: $debtBox-paid-color;
            }

            svg path {
              stroke: $success-500-color;
            }
          }

          &.unpaid {
            background-color: $debtBox-unpaid-bg-color;

            span {
              color: $debtBox-unpaid-color;
            }

            svg path {
              fill: $debtBox-unpaid-color;
            }
          }

          &.processing {
            background-color: $debtBox-processing-bg-color;

            span {
              color: $debtBox-processing-color;
            }
          }

          &.corrected {
            background-color: $debtBox-corrected-bg-color;

            span {
              color: $debtBox-corrected-color;
            }

            svg path {
              fill: $light-color;
            }
          }
        }
      }

      .order-actions span {
        font-size: $size-12;
        color: $light-grey-color;
      }

      &.empty-cell {
        height: $size-60;
      }
    }
  }

  .table-rowCollapse {
    .table-rowCollapse-cell {
      padding: 0;
      border-bottom: none;
    }

    &.open .MuiTableCell-root .MuiCollapse-root {
      border: $size-1 solid $light-color;
    }
  }

  &.table-default .table-row {

    &:nth-child(2n-1) {
      background-color: $table-gray-color;
      border: $size-1 solid $table-gray-color;

      .add-template-button {
        button {
          background-color: $white-color;
        }
      }
    }
  }

  &.table-collapse .table-row {

    &:nth-child(4n-3) {
      background-color: $table-gray-color;
      border: $size-1 solid $table-gray-color;
    }
  }

  &.table-default .table-row,
  &.table-collapse .table-row {
    &.open {
      background-color: $light-color;
      border-left: $size-1 solid $light-color;
      border-right: $size-1 solid $light-color;

      .table-row-cell {
        border-bottom: $size-1 solid $light-color;

        .table-row-cell-buttons {
          .table-row-cell-buttons-delete svg path {
            fill: $white-color;
          }

          .table-row-cell-buttons-edit svg path {
            stroke: $white-color;
          }
        }

        .table-row-cell-add svg {
          rect {
            stroke: $white-color;
          }

          path {
            fill: $white-color;
          }
        }

        .table-row-cell-download svg {
          path {
            fill: $white-color;
          }
        }

        span {
          color: $white-color;

          &.table-row-cell-debtBox {

            &.paid,
            &.unpaid,
            &.processing,
            &.corrected {
              background-color: $white-color;

              span {
                color: $light-color;
              }
            }

            &.paid svg path {
              stroke: $light-color;
            }

            &.unpaid svg path {
              fill: $light-color;
            }
          }
        }
      }
    }
  }

  &.cell-small {
    .table-row-cell {
      padding: $size-9 $size-24;
      height: $size-60;
    }
  }

  &.cell-default {
    .table-row-cell {
      padding: $size-20 $size-24;
      height: $size-60;
    }
  }

}

.table-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $size-23;
  position: relative;

  .table-bottom-perPageOption {
    position: absolute;
    left: 0;

    .MuiSelect-select {
      font-size: $size-14;
      font-family: $font-semibold;
      color: $dark-color;
    }

    .MuiSelect-icon {
      top: calc(50% - 0.2em);
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  .table-bottom-pagination {
    .MuiPagination-ul {
      gap: $size-3;

      .MuiPaginationItem-circular {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $dark-color;
        font-size: $size-14;
        font-family: $font-medium;
        line-height: $size-20;
        margin: 0;
        height: $size-40;
        width: $size-40;

        &:hover,
        &.Mui-selected,
        &.Mui-focusVisible {
          background-color: $table-gray-color;
          border-radius: $size-8;
        }

        &.MuiPaginationItem-ellipsis:hover {
          background-color: transparent;
        }

        .MuiPaginationItem-icon {
          height: $size-14;
        }
      }
    }

    &.mobile {
      .MuiPagination-ul {
        .MuiPaginationItem-circular {
          height: $size-32;
          width: $size-32;
        }
      }
    }
  }

}

.table-empty {
  color: $dark-color;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $size-400;
}

.table-bottom-perPageOption-menuItem {
  color: $dark-color !important;
}