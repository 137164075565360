@import '../../../styles/Reset';

.ftpConnectionDenied {
  max-width: $size-550;
  margin: $size-100 auto;
  padding: $size-44;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &-icon {
    margin-bottom: $size-10;

    svg {
      width: $size-44;
      height: $size-44;
    }
  }

  &-title {
    margin-bottom: $size-30;

    p {
      font-size: $size-22;
      color: $dark-color;
    }
  }

  &-text {

    p,
    a {
      font-size: $size-16;
      line-height: $size-20;
      color: $light-color;
    }

    p a {
      text-decoration: underline;
    }
  }
}