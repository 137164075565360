@import '../../styles/Reset';

@mixin flex-items-center {
  display: flex;
  align-items: center;
}

.ftp-desktop-view {
  .request-requested-view,
  .not-requested-view {
    @include flex-items-center();
    flex-direction: column;
    max-width: $size-462;
    margin: $size-100 auto 0;
    text-align: center;

    .doopic-logo {
      width: $size-30;
      margin-bottom: $size-14;
    }

    .title-container {
      margin-bottom: $size-30;

      p {
        color: $dark-color;
      }
    }

    .description-container {
      margin-bottom: $size-22;

      .description-paragraph {
        margin-bottom: $size-20;
      }
    }

    .request-btn-container {
      max-width: $size-265;

      button {
        @include flex-items-center();
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        gap: $size-7;
      }
    }
  }
}