@import '../../styles/Reset';

.checkout-page {
  padding: $size-21 0;

  .tableTopInfo {
    margin-bottom: $size-23;
  }

  .table-head-cell {
    white-space: nowrap;
  }

  .checkout-table-description {
    display: flex;
    font-size: $size-14;
    line-height: $size-16;
    white-space: nowrap;

    .checkout-table-description-link {
      display: inline-flex;
      align-items: center;

      &.icon {
        a:hover {
          border-bottom: none;
        }
      }

      a {
        display: inline-flex;
        align-items: center;
        color: $dark-color;
        font-size: $size-14;
        line-height: $size-16;
        margin-left: $size-3;

        .checkout-table-description-link-icon {
          width: $size-16;
          height: $size-16;
        }

        span {
          display: inline-flex;
          align-items: center;
          color: $dark-color;
          font-size: $size-14;
          line-height: $size-16;
          margin-left: $size-3;
        }
      }
    }
  }

  .checkout-table-amount {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

    &.hasDiscount {
      margin-top: $size-10;
      max-height: $size-7;
    }

    .checkout-table-amount-price {
      display: block;
      font-size: $size-14;
      line-height: $size-20;

      &.hasDiscount {
        margin-top: (-$size-12);
      }
    }

    .checkout-table-amount-discount {
      color: $input-focus-color !important;
      font-size: $size-12;
      line-height: $size-12;
      white-space: nowrap;
    }
  }

  .table-bottom-totalPrice {
    display: flex;
    justify-content: flex-end;
    color: $dark-color;
    margin-bottom: $size-36;
    padding: $size-16 $size-24;

    .table-bottom-totalPrice-title {
      margin-right: $size-24;
    }
  }

  .table-bottom-btn-wrapper {
    display: flex;
    justify-content: flex-end;

    .button {
      padding: $size-8 $size-18;
      font-size: $size-16;
      line-height: $size-20;
    }
  }

  .payment-methods-modal {
    .modal {
      padding: $size-27 $size-40 $size-30;
    }
  }

}