@import '../../styles/Reset';

.modal--wrap {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  z-index: $modal-z-index;
  overflow: hidden;
  background-color: $modal-bg-color;

  .modal {
    display: flex;
    flex-direction: column;

    position: relative;
    left: 50%;
    top: $center-top;
    transform: translate(-50%, (-$center-top));

    border-radius: $size-12;

    background-color: $white-color;
    box-shadow: 0 $size-4 $size-20 rgba(0, 0, 0, 0.25);

    width: $size-400;
    max-width: 90vw;
    min-height: $size-140;
    padding: $size-24 $size-24;
  }

  .modal-small {
    width: $size-400;
  }

  .modal-medium {
    width: $size-550;
  }

  .modal-large {
    width: $size-700;
  }

  .modal-xl {
    width: $size-700;
  }

  .modal--center {
    text-align: center;
  }
}