@import '../../../../styles/Reset';

.filter-box {
  position: absolute;
  z-index: 2;
  top: $size-48;
  left: 50%;
  transform: translateX(-50%);
  background-color: $white-color;
  border-radius: $size-8;
  padding: $size-6;
  border: $size-1 solid $table-row-border-color;
  width: $size-290;
  box-shadow: 0 $size-4 $size-6 (-$size-2) rgba(16, 24, 40, 0.03), 0 $size-12 $size-16 (-$size-4) rgba(16, 24, 40, 0.08);

  .filter-box-top {
    display: flex;
    justify-content: space-between;
    padding: $size-9 $size-10;
    border-bottom: $size-1 solid $table-row-border-color;
    color: $gray-700-color;

    .filter-box-reset {
      background-color: transparent;
      text-decoration: underline;
      color: $dark-color;
    }
  }

  .filter-box-date {
    display: flex;
    align-items: center;
    padding: $size-11 $size-9;

    .filter-box-date-hyphen {
      font-size: $size-14;
      line-height: $size-22;
      color: $light-color;
      margin: 0 $size-8;
    }



    .MuiFormControl-root {
      min-width: auto;

      .MuiInputBase-root {
        width: $size-120;
        height: $size-36;
        padding-right: $size-8 !important;

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border: $size-2 solid $not-logged-dark-color;
          }
        }

        .MuiInputBase-input {
          padding: $size-8 0 $size-4 $size-8 !important;
          font-size: $size-13;
          color: $input-color !important;
          height: $size-36;
        }

        .MuiIconButton-root {
          padding: $size-4 !important;
          margin-top: $size-4;
          position: relative;
          left: (-$size-5);

          .MuiSvgIcon-root {
            width: $size-16;
            height: $size-16;
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border: $size-1 solid $dark-color;
        }
      }
    }

  }

  .filter-box-content {
    max-height: $size-450;
    overflow-y: auto;
    padding-right: $size-10;

    .filter-box-radioGroup {
      display: flex;

      .filter-box-radioGroup-icons {
        display: flex;
        flex-direction: column;

        li {
          padding: $size-9 $size-8 $size-9 $size-10;

          svg {
            width: $size-18;
            height: $size-18;

            path {
              fill: $gray-700-color;
            }
          }
        }
      }

      .MuiFormGroup-root {
        width: 100%;

        .MuiFormControlLabel-root {
          padding: $size-9 $size-10;
          padding-left: 0;
          margin-bottom: $size-4;
          width: 100%;
          display: flex;
          flex-direction: row-reverse;
          margin-left: 0;
          margin-right: 0;

          .MuiRadio-root {
            padding: 0;
            padding-right: $size-1;
          }

          .MuiFormControlLabel-label {
            margin-right: auto;
            color: $gray-700-color;
          }
        }
      }

    }

    .filter-box-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: $size-9 $size-10;
      margin-bottom: $size-4;
      color: $gray-700-color;

      .filter-box-row-title {
        display: flex;
        align-items: center;
        gap: $size-8;
        background-color: transparent;
        cursor: pointer;

        .workflow-icon {
          width: $size-18;
          height: $size-18;
        }
      }

      .MuiCheckbox-root {
        padding: 0 $size-1 0 0;
      }
    }

    .filter-box-line {
      margin: $size-12 0;
      background-color: $table-row-border-color;
      height: $size-1;
    }

  }

  .filter-box-apply {
    display: flex;
    justify-content: center;
    padding: $size-10 $size-16;
    margin-bottom: $size-2;
  }

  .cursor-default {
    cursor: default !important;
  }
}


.css-1yllih9-MuiPaper-root-MuiPickersPopper-paper {
  box-shadow: 0 $size-4 $size-16 0 rgba(0, 0, 0, 0.25) !important;
}

.css-cwhad8-MuiDateCalendar-root {
  width: $size-260 !important;

  .css-154cs8t-MuiPickersYear-root {
    flex-basis: 26%;
  }

  .Mui-selected {
    background-color: $not-logged-dark-color !important;
    color: $white-color !important;
  }

  .MuiPickersDay-root {
    width: $size-32;
    height: $size-32;
    color: $dark-color;
    font-size: $size-11;
    font-family: $font-medium;
  }

  .MuiPickersCalendarHeader-root {
    justify-content: center;
    padding-left: $size-48;

    .MuiPickersCalendarHeader-switchViewButton {
      display: none;
    }

    .MuiPickersCalendarHeader-labelContainer {
      margin-right: initial;
    }

    .MuiIconButton-edgeEnd {
      position: absolute;
      left: 0;
      top: $size-12;
    }

    .MuiIconButton-edgeStart {
      position: absolute;
      right: 0;
      top: $size-12;
    }
  }
}