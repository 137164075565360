@import '../../styles/Reset';

$rate-bg-color: #3D96F7;

@keyframes ratingOpen {
  from {
    bottom: (-$size-37);
  }

  to {
    bottom: 0;
  }
}

@keyframes ratingClose {
  from {
    bottom: 0;
  }

  to {
    bottom: (-$size-37);
  }
}

@mixin flex-items-center {
  display: flex;
  align-items: center;
}

.dashboard-page {

  .dashboard-info-boxs {
    display: flex;
    flex-wrap: wrap;
    gap: $size-24;
    margin: $size-23 0;

    &.mobile {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .dashboard-info-box:last-child {
      .dashboard-info-box__content p {
        line-height: $size-12 !important;
      }

      .dashboard-info-box__content.no-order p {
        line-height: $size-38 !important;
      }
    }

    .orders-stat--next-eta--seconds {
      display: inline-block;
      font-size: $size-15;
      padding-top: $size-14;
      font-family: $font-semibold;
    }
  }

  .orderTable {
    .table-head {
      .table-head-cell {

        &:nth-child(2),
        &:nth-child(3) {
          padding: 0 $size-16;
        }

        &:nth-child(6) {
          padding: 0 $size-6;
        }
      }
    }

    .table-row {
      cursor: pointer;

      &.cursor-default {
        cursor: default;
      }

      .table-row-cell {
        padding-top: 0;
        padding-bottom: 0;

        &:nth-child(2),
        &:nth-child(3) {
          padding: 0 $size-16;
        }

        &:nth-child(6) {
          padding: 0 $size-6;
        }

        .order-partial-delivery-count {
          position: relative;
          color: #12B76A;

          svg {
            position: absolute;
            top: $size-3;
            left: (-$size-16);
          }
        }

        .order-name {
          display: flex;
          align-items: center;
          gap: $size-10;

          .order-name-text {
            display: flex;
            flex-direction: column;
            word-break: $break-word;

            .order-template-name {
              span {
                font-size: $size-12;
                color: $light-grey-color;
              }
            }
          }

          .order-name-badge {
            display: flex;
            align-items: center;

            .badge-arrow {
              display: inline-block;
              vertical-align: middle;
              width: $size-15;
              border-right: solid $size-10 $orange-500-color;
              border-bottom: solid $size-10 transparent;
              border-top: solid $size-10 transparent;
            }

            .badge-text {
              height: $size-20;
              background-color: $orange-500-color;
              display: block;
              color: $white-color;
              padding: 0 $size-10 0 $size-4;
              border-radius: 0 $size-3 $size-3 0;
            }
          }
        }

        .order-prioritised-icon {
          display: flex;
          align-items: center;
        }

        .order-formats span {
          color: $light-grey-color;
        }
      }

      &.open {

        .order-formats span,
        .orderTable-timing-text {
          color: $white-color;
        }

        .orderTable-status {

          &.success-300,
          &.success-400,
          &.success-500,
          &.orange-500,
          &.gray {
            background-color: $white-color;
          }

          svg path {
            fill: $light-color;
          }
        }

        .order-template-name span {
          color: $white-color !important;
        }

        .order-prioritised-icon {
          svg {
            path {
              fill: $white-color;
            }

            rect {
              stroke: $white-color;
            }
          }
        }
      }
    }

    .newRegister-box {
      margin-top: $size-80;

      .create-order-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $size-8;
        font-size: $size-14;
        font-family: $font-semibold;

        svg {
          path {
            stroke: $white-color;
          }
        }
      }
    }

    .table-empty {
      .h6 {
        text-align: center;
      }

      .support-link {
        font-family: $font-semibold;
        font-size: $size-20;
        text-decoration: none;
        border-bottom: $size-1 solid;
        color: $dark-color;
        border-bottom: $size-1 solid $dark-color;
      }
    }
  }

  .rate-container {
    background-color: $rate-bg-color;
    border-radius: $size-8 $size-8 0 0;
    position: fixed;
    bottom: (-$size-37);
    right: $size-54;
    z-index: $z-index-4;

    &.open {
      animation: ratingOpen .5s forwards;
    }

    &.closed {
      animation: ratingClose .5s forwards;
    }

    .rate-top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      gap: $size-9;
      padding: $size-9 $size-24 $size-9 $size-13;
      cursor: pointer;

      p {
        color: $white-color;
      }

      .close-button {
        position: absolute;
        top: $size-6;
        right: $size-6;
        background-color: transparent;

        svg {
          width: $size-7;
        }
      }
    }

    .rate-bottom {
      background-color: #F9FAFB;
      height: $size-37;
      display: flex;
      align-items: center;
      justify-content: center;

      .rate-stars {
        display: flex;
        align-items: center;

        .hovered {
          path {
            fill: $orange-500-color;
            stroke: $orange-500-color;
          }

          &.last-child {
            path {
              fill: $orange-700-color;
              stroke: $orange-700-color;
            }
          }

          &.last-child~.fill {
            path {
              fill: transparent;
              stroke: $light-color;
            }
          }
        }

        .rate-star {
          background-color: transparent;
          width: $size-19;
          height: $size-19;
          display: flex;
          align-items: center;
          justify-content: center;

          &.disabled {
            cursor: default;

            &:hover {
              path {
                fill: transparent;
                stroke: $light-color;
              }
            }

            &.fill:hover {
              path {
                fill: $orange-500-color;
                stroke: $orange-500-color;
              }
            }
          }

          &.fill {
            path {
              fill: $orange-500-color;
              stroke: $orange-500-color;
            }
          }
        }
      }
    }
  }

  .billing-address-modal {
    .modal--wrap {
      z-index: 0;
    }

    .modal {
      padding: $size-27 $size-40 $size-30;
    }
  }
}

.dashboard-pricing-tooltip {
  padding: $size-9 $size-8;
}

.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
  width: 100vw;
  z-index: 9997;

  &.shepherd-modal-is-visible {
    height: 100vh;
    opacity: .4;
    transform: translateZ(0);
    transition: all .3s ease-out, height 0s 0s, opacity .3s 0s;

    path {
      pointer-events: all;
    }
  }
}

.shepherd-element {
  background: $white-color;
  border-radius: $size-5;
  box-shadow: 0 $size-1 $size-4 #0003;
  max-width: $size-365;
  opacity: 0;
  outline: none;
  transition: opacity .3s, visibility .3s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;

  &.shepherd-enabled {
    opacity: 1;
    visibility: visible;
  }

  .shepherd-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top-left-radius: $size-4;
    border-top-right-radius: $size-4;
    padding: $size-20 $size-20 $size-10;

    .shepherd-title {
      font-size: $size-16;
      font-family: $font-medium;
      color: $dark-color;
    }

    .shepherd-cancel-icon {
      background-color: transparent;

      span {
        color: transparent;
        background-image: url('../../svg/close.svg');
        background-repeat: no-repeat;
        background-size: $size-14;
        display: inline-block;
        width: $size-14;
        min-width: $size-14;
      }
    }
  }

  .shepherd-text {
    font-size: $size-16;
    color: $dark-color;
    margin: 0 $size-20;
    padding-bottom: $size-20;
    line-height: $size-22;
    border-bottom: $size-1 solid $light-color;

    .shepherd-step {
      padding-left: $size-8;
      font-size: $size-14;
      font-family: $font-medium;
      position: absolute;
      bottom: $size-25;
    }
  }

  .shepherd-footer {
    padding: $size-17 $size-20 $size-15 $size-20;
    display: flex;
    justify-content: flex-end;
    gap: $size-15;

    .shepherd-button {
      height: $size-36;
      border-radius: $size-4;
      font-size: $size-14;
      font-family: $font-semibold;
      color: $white-color;
      padding-left: $size-15;
      padding-right: $size-15;
    }

    .shepherd-button-back {
      background-color: $input-border-color;

      &.shepherd-button-disabled {
        opacity: 40%;
        pointer-events: none;
        cursor: default;
      }
    }

    .shepherd-button-next {
      background-color: $not-logged-dark-color;
    }
  }

  .shepherd-arrow,
  .shepherd-arrow:before {
    position: absolute;
    width: $size-16;
    height: $size-16;
    z-index: -1
  }

  .shepherd-arrow:before {
    background: $white-color;
    content: "";
    transform: rotate(45deg)
  }

  &[data-popper-placement^=bottom] {
    margin-top: $size-20;
  }

  &[data-popper-placement^=right] {
    margin-left: $size-20;
  }

  &[data-popper-placement^=top]>.shepherd-arrow {
    bottom: -8px
  }

  &[data-popper-placement^=bottom]>.shepherd-arrow {
    top: -8px
  }

  &[data-popper-placement^=left]>.shepherd-arrow {
    right: -8px
  }

  &[data-popper-placement^=right]>.shepherd-arrow {
    left: -8px
  }

  &.shepherd-centered>.shepherd-arrow {
    opacity: 0
  }
}