@import '../../styles/Reset';

$button-xs-width: $size-300;
$button-xs-height: $size-30;
$button-xs-padding: $size-10;
$button-xs-font-size: $size-12;

$button-s-width: $size-360;
$button-s-height: $size-36;
$button-s-padding: $size-12;
$button-s-font-size: $size-14;

$button-xl-width: $size-420;
$button-xl-height: $size-42;
$button-xl-padding: $size-14;
$button-xl-font-size: $size-16;

$button-color: #FFFFFF;
$button-bg-color: #231975;
$button-bg-hover-color: #3A3183;
$button-shadow-color: rgba(16, 24, 40, .05);

$contained-button-color: #667085;
$contained-button-bg-color: #F8F8F8;
$contained-button-bg-hover-color: #EEE;
$contained-button-shadow-color: rgba(16, 24, 40, .05);

$outlined-button-color: #344054;
$outlined-button-bg-color: #FFFFFF;
$outlined-button-bg-hover-color: #f6f6f6;
$outlined-button-border-color: #D0D5DD;

$disabled-button-color: #D0D5DD;
$disabled-button-bg-color: #FFFFFF;
$disabled-button-bg-hover-color: #FFFFFF;
$disabled-button-border-color: #D0D5DD;

.button, .button--s {
  color: $button-color;
  background-color: $button-bg-color;
  width: $button-s-width;
  height: $button-s-height;
  max-width: 100%;
  line-height: $button-s-height;
  padding: 0 $button-s-padding;
  overflow: hidden;
  white-space: nowrap;
  font-size: $button-s-font-size;
  font-family: $font-semibold;
  box-shadow: 0 $size-1 $size-2 $button-shadow-color;
  border-radius: $size-4;

  &.button--fit-content {
    padding: 0 calc(2 * #{$button-s-padding});
  }
}

.button--xs {
  width: $button-xs-width;
  height: $button-xs-height;
  line-height: $button-xs-height;
  padding: 0 $button-xs-padding;
  font-size: $button-xs-font-size;

  &.button--fit-content {
    padding: 0 calc(2 * #{$button-xs-padding});
  }
}

.button--xl {
  width: $button-xl-width;
  height: $button-xl-height;
  line-height: $button-xl-height;
  padding: 0 $button-xl-padding;
  font-size: $button-xl-font-size;

  &.button--fit-content {
    padding: 0 calc(2 * #{$button-xl-padding});
  }
}

.button--contained {
  color: $contained-button-color;
  background-color: $contained-button-bg-color;
  box-shadow: none;
}

.button--fit-content {
  width: fit-content;
}

.button--outlined {
  color: $outlined-button-color;
  background-color: $outlined-button-bg-color;
  box-shadow: none;
  border: $size-1 solid $outlined-button-border-color;
}

.button:hover {
  @media (min-width: $mobile-breakpoint-width) {
    background-color: $button-bg-hover-color;
  }
}

.button--contained:hover {
  background-color: $contained-button-bg-hover-color;
}

.button--outlined:hover {
  background-color: $outlined-button-bg-hover-color;
}

.button:disabled, .button--s:disabled, .button--xs:disabled, .button--xl:disabled {
  color: $disabled-button-color;
  background-color: $disabled-button-bg-color;
  box-shadow: none;
  border: $size-1 solid $disabled-button-border-color;
}

.button--warning {
  background-color: $input-warning-bg-color;
  white-space: nowrap;
}

.button--warning:hover {
  background-color: $input-warning-bg-hover-color;
}
