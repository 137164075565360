@import '../../../../styles/Reset';

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

$border-color: #EAECF0;
$icon-border-color: #F2F4F7;
$primary-border-color: #447AF0;

@keyframes primary-change {
  0% { border-color: $border-color; }
  30% { border-color: $primary-border-color; }
  70% { border-color: $primary-border-color; }
  100% { border-color: $border-color; }
}

.payment-method-list {
  display: flex;
  flex-direction: column;
  gap: $size-30;
  overflow-y: auto;

  .payment-method-item {
    @include flex-row;
    align-items: center;
    border: $size-1 solid $border-color;
    border-radius: $size-8;
    padding: $size-16;

    &.primary {
      animation: primary-change 2.5s ease-in-out;
    }

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $size-58;
      height: $size-40;
      border-radius: $size-6;
      border: $size-1 solid $icon-border-color;
    }

    .method-info {
      margin-left: $size-16;
    }

    .method-actions {
      @include flex-row;
      align-items: center;
      margin-left: auto;

      button {
        background-color: transparent;
        line-height: $size-13;

        &:disabled {
          opacity: 0.5;
        }
      }

      .primary-container {
        margin-right: $size-36;
      }

      .set-primary-btn {
        margin-right: $size-26;
      }

      .info-icon {
        margin-right: $size-35;
      }

      .warning-icon {
        margin-right: $size-45;
      }
    }
  }

  &.scrollable {
    padding-right: $size-14;
    padding-bottom: $size-14;
  }
}

#delete-payment-method-dialog {
  .modal--wrap {
    background-color: transparent !important;
  }
}