@import '../../../../styles/Reset';

.new-order-page {

  .retail-section {
    .section-top {
      background-color: $switch-active-color;
      color: $white-color;
    }

    .section-content {
      padding: $size-20;
      display: grid;
      gap: $size-10;

      ul {
        list-style: disc;
        padding-left: $size-14;
      }
    }
  }

}