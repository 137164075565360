@import '../../../styles/Reset';

$box-border-color: #EAECF0;
$tooltip-bg-color: #101828;
$icon-border-color: #F2F4F7;

.billing-section {
  display: flex;
  flex-direction: column;
  gap: $size-23;

  .tableTopInfo {
    max-width: $size-550;
    padding: 0 0 0 $size-16;
  }

  .top-boxes {
    display: flex;
    flex-direction: row;
    gap: $size-40;

    .box {
      border: $size-1 solid $box-border-color;
      border-radius: $size-8;
      width: $size-300;
      max-width: $size-300;
      height: $size-165;
      padding: $size-24 $size-22 $size-21;
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.flex-start {
        justify-content: flex-start;
      }

      .box-title {
        display: flex;
        flex-direction: row;
        align-content: center;
        margin-bottom: $size-12;

        .payment-methods-button {
          background-color: transparent;
          margin-left: auto;
          border-bottom: $size-1 solid;
        }
      }

      &:last-of-type {
        min-width: $size-570;
      }

      .box-icon {
        margin-right: $size-8;
      }

      .doop-icon {
        width: $size-18;
      }

      .box-chip {
        background-color: $not-logged-dark-color;
        position: absolute;
        top: $size-10;
        right: (-$size-14);
        width: $size-97;
        height: $size-24;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          color: $white-color;
        }
      }

      .edit-btn-container {
        position: absolute;
        bottom: $size-10;
        right: $size-3;
        display: flex;
        justify-content: flex-end;
        padding-right: $size-14;

        .edit-button {
          background-color: transparent;
        }
      }

      ul {
        list-style: disc;
        padding-left: $size-13;
      }

      .upgrade-btn-container {
        display: flex;
        justify-content: flex-end;
        padding-top: $size-22;
        margin-right: (-$size-11);

        .upgrade-plan-button {
          background-color: transparent;
          display: flex;
          gap: $size-8;
          color: $dark-color;
        }
      }

      .payment-method-box {
        width: 100%;
        border-radius: $size-8;
        border: $size-1 solid $box-border-color;
        display: flex;
        justify-content: space-between;
        padding: $size-16;

        .left,
        .right {
          display: flex;
          flex-direction: row;
        }

        .left {
          gap: $size-16;

          .icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $size-58;
            height: $size-40;
            border-radius: $size-6;
            border: $size-1 solid $icon-border-color;
          }

          .card-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }

        .right {
          gap: $size-25;
          align-items: center;
          padding-right: $size-17;
        }
      }

      .no-method-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    }
  }

  .search-row {
    display: flex;
    flex-direction: row;
    gap: $size-40;
    width: $size-300;

    .input-container {
      flex: 1;
      width: 100%;

      .input--wrap {
        width: 100%;

        .input {
          border: $size-1 solid $table-row-border-color;

          &:focus {
            box-shadow: none;
            border-color: $dark-color;
          }
        }
      }
    }
  }

  .table-container {
    .status-chip {
      min-width: $size-55;
      max-height: $size-22;
      cursor: default;

      .MuiChip-label {
        display: flex;
        align-items: center;
        gap: $size-6;
        padding: 0 $size-6;
      }

      &.chip-unpaid {
        background-color: $debtBox-unpaid-bg-color;

        .MuiChip-label {
          color: $debtBox-unpaid-color;
        }
      }

      &.chip-processing {
        background-color: $debtBox-processing-bg-color;

        .MuiChip-label {
          color: $debtBox-processing-color;
        }
      }

      &.chip-corrected {
        background-color: $debtBox-corrected-bg-color;

        .MuiChip-label {
          color: $debtBox-corrected-color;

          svg {
            width: $size-12;
            height: $size-12;
          }
        }
      }

      &.chip-paid {
        background-color: $debtBox-paid-bg-color;

        .MuiChip-label {
          color: $debtBox-paid-color;
        }
      }
    }
  }

  .table-empty {
    height: $size-100;
  }

  .billing-address-modal,
  .payment-methods-modal,
  .sepa-information-modal {
    .modal {
      padding: $size-27 $size-40 $size-30;
    }
  }

  .sepa-information-modal {
    .mandatetext {
      font-size: $size-14;

      p {
        margin-bottom: $size-16;
      }

      dl {
        overflow: auto;
      }

      dt {
        float: left;
        font-family: $font-semibold;
        clear: left;
        width: $size-270;
      }

      dt+dd {
        float: left;
        clear: none;
        margin-left: 0;
      }
    }
  }

}

.balance-container {
  border: $size-2 solid $debtBox-unpaid-color;
  border-radius: $size-11;
  padding: $size-12;
  min-width: $size-230;
  margin-bottom: $size-14;
}