@import '../../../styles/Reset';

$picture-bg-color: #CCCCCC;

.profile-picture-container {
  border-radius: 50%;
  overflow: hidden;

  .profile-picture {
    width: 100%;
  }

  .name-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $picture-bg-color;

    p {
      color: $white-color;
    }
  }
}