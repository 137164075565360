@import '../../styles/Reset';

.forgot-page {
  display: flex;
  height: 100vh;
  min-height: $size-700;

  .forgot-form--wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .forgot-form__line {
    width: $size-360;
  }

  .back-arrow {
    position: relative;
    top: $size-1;
    height: $size-12;
    margin-right: $size-3;
  }
}
