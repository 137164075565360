@import "../../../../styles/Reset";

.order-table-content {
  .imagesDetail-download {
    padding: 0 $size-12 0 $size-36;
    color: $dark-color;
    max-width: $size-270;
    width: 100%;

    .imagesDetail-download-title {
      margin-bottom: $size-7;
    }

    .imagesDetail-download-subtitle {
      display: flex;
      align-items: center;
      gap: $size-8;
      margin-bottom: $size-8;

      svg {
        width: $size-20;
        height: $size-20;
      }

      p,
      a {
        font-size: $size-13;
        line-height: $size-18;
        font-family: $font-semibold;
        color: $dark-color;
      }

      a {
        text-decoration: none;
        border-bottom: $size-1 solid;
      }
    }

    .imagesDetail-download-infoText {
      margin: $size-7 0;

      p,
      a {
        font-size: $size-11;
      }
    }

    .imagesDetail-download-testButton {
      a {
        text-decoration: none;

        button {
          display: flex;
          align-items: center;
          gap: $size-4;
          font-size: $size-14;

          svg {
            path {
              fill: $white-color;
            }

            rect {
              stroke: $white-color;
            }
          }
        }
      }
    }

    .imagesDetail-download-buttons {
      margin-top: $size-11;
      display: flex;
      flex-wrap: wrap;
      gap: $size-6;

      a {
        button {
          color: $white-color;
          text-decoration: none;
          min-width: $size-62;
          white-space: nowrap;

          svg {
            position: relative;
            top: $size-4;
            left: (-$size-3);
            width: $size-18;
            height: $size-18;

            path {
              fill: $white-color;
            }
          }
        }
      }
    }
  }
}