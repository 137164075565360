@import '../../../styles/Reset';

.dashboard-info-box {
  max-width: $size-230;
  width: 100%;
  min-height: $size-122;
  padding: $size-24;
  border: $size-1 solid $table-row-border-color;
  border-radius: $size-8;
  color: $dark-color;

  &.mobile {
    padding: $size-14;
  }

  .dashboard-info-box__title{
    margin-bottom: $size-8;
  }
}