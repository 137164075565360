@import '../../../../styles/Reset';

.new-order-page {
  .new-order-section {
    .section-top {
      display: flex;
      align-items: center;
      background-color: $table-gray-color;
      padding: $size-10 $size-24;
      gap: $size-15;
      color: $dark-color;
      width: 100%;

      &:hover {
        background-color: darken($table-gray-color, 5%);
      }

      label {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: $size-8;

        .section-top-icon {
          display: flex;
          align-items: center;

          svg {
            width: $size-18;
            height: $size-18;

            path {
              fill: $dark-color;
            }
          }

          .retouch-icon {
            line {
              stroke: $dark-color;
            }

            path {
              stroke: $dark-color;
              fill: transparent;
            }
          }
        }
      }
    }

    &.active {
      .section-top {
        background-color: $switch-active-color;
        color: $white-color;

        .section-top-icon {
          svg {
            path {
              fill: $white-color;
            }
          }

          .retouch-icon {
            line {
              stroke: $white-color;
            }

            path {
              stroke: $white-color;
              fill: transparent;
            }
          }
        }

        .info-modal-icon svg path {
          fill: $white-color;
        }
      }
    }
  }
}