@import '../../styles/Reset';

$input-xs-width: $size-300;
$input-xs-height: $size-30;
$input-xs-padding: $size-10;
$input-xs-font-size: $size-12;
$input-xs-label-margin: $size-4;
$input-xs-error-margin: $size-6;

$input-s-width: $size-360;
$input-s-height: $size-36;
$input-s-padding: $size-12;
$input-s-font-size: $size-14;
$input-s-label-margin: $size-6;
$input-s-error-margin: $size-8;

$input-xl-width: $size-420;
$input-xl-height: $size-42;
$input-xl-padding: $size-14;
$input-xl-font-size: $size-16;
$input-xl-label-margin: $size-8;
$input-xl-error-margin: $size-10;

@mixin adornment {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.input {
  background-color: $white-color;
  color: $input-color;
  width: 100%;
  height: $input-s-height;
  line-height: $input-s-height;
  padding: 0 $input-s-padding;
  font-size: $input-s-font-size;
  border: $size-1 solid $input-border-color;
  border-radius: $size-4;
  outline: none;

  &:disabled {
    pointer-events: none;
  }
}

.input::placeholder {
  color: $input-placeholder-color;
}

.input:focus {
  box-shadow: 0 0 0 $size-1 $input-focus-color;
  border: $size-1 solid $input-focus-color;
}

.input:disabled {
  color: $input-disabled-color;
}

.input__label, .input__error {
  text-align: left;
  white-space: nowrap;
  font-size: $input-s-font-size;
  height: $size-20;
  line-height: $size-20;
}

.input__label {
  color: $input-label-color;
  font-family: $font-medium;
  margin-bottom: $input-s-label-margin;
}

.input__error {
  color: $input-error-color;
  margin-top: $input-s-error-margin;
  margin-bottom: 0;
}

.input--error, .input--error:focus {
  box-shadow: 0 0 0 $size-1 $input-error-color;
  border: $size-1 solid $input-error-color;
}

.input--wrap, .input--s--wrap {
  width: $input-s-width;
  max-width: 100%;
}

.input--xs--wrap {
  width: $input-xs-width;

  .input {
    height: $input-xs-height;
    line-height: $input-xs-height;
    padding: 0 $input-xs-padding;
    font-size: $input-xs-font-size;
  }

  .input__label {
    font-size: $input-xs-font-size;
    margin-bottom: $input-xs-label-margin;
  }

  .input__error {
    font-size: $input-xs-font-size;
    margin-top: $input-xs-error-margin;
  }
}

.input--xl--wrap {
  width: $input-xl-width;

  .input {
    height: $input-xl-height;
    line-height: $input-xl-height;
    padding: 0 $input-xl-padding;
    font-size: $input-xl-font-size;
  }

  .input__label {
    font-size: $input-xl-font-size;
    margin-bottom: $input-xl-label-margin;
  }

  .input__error {
    font-size: $input-xl-font-size;
    margin-top: $input-xl-error-margin;
  }
}

.input-container {
  position: relative;
}

.input__start-adornment {
  @include adornment;
  left: $size-15;
}

.input__end-adornment {
  @include adornment;
  right: $size-10;
}

.not-logged-page {
  .input__label,
  .input {
    color: $not-logged-input-label-color;
  }
}
