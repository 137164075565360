@import '../../styles/Reset';

$not-found-color: #231975;
$error-type-color: #f6f6f6;
$error-descr-color: #3A3183;

.error-page {
  display: flex;
  height: 100vh;

  .error--wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .error-type {
    color: $error-type-color;
    height: $size-43;
  }

  .error-title {
    padding: 0 $size-60;

    h2 {
      color: $not-found-color;
    }
  }

  .error-descr {
    p {
      color: $error-descr-color;
    }
  }

  .button-take-home {
    margin: inherit $size-35;
  }

  @media (min-width: $mobile-breakpoint-width) {
    .error-type {
      height: $size-61;
    }
  }
}