@import '../../../styles/Reset';

$admin-chip-bg-color: #231975;
$seperator-border-color: #F6F6F6;
$tooltip-bg-color: #101828;

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

.my-details {
  @include flex-row;

  .my-details-section {
    max-width: $size-638;
    margin-top: $size-4;

    @media (min-width: $mobile-breakpoint-width) {
      padding-left: $size-16;
    }

    .my-details-title {
      display: flex;
      align-items: center;
      margin-bottom: $size-8;

      .admin-user-chip {
        background-color: $admin-chip-bg-color;
        color: $white-color;
        border-radius: $size-4;
        line-height: $size-18;
        padding: $size-2 $size-8;
        height: $size-22;
        margin-left: $size-10;

        span {
          padding: 0;
          font-family: $font-medium;
        }
      }
    }

    .my-details-description {
      margin-bottom: $size-28;

      @media (min-width: $mobile-breakpoint-width) {
        margin-bottom: $size-29;
      }
    }

    .profile-image-container {
      @include flex-row;
      align-items: center;
      gap: $size-26;
      margin-bottom: $size-23;

      @media (min-width: $mobile-breakpoint-width) {
        margin-bottom: $size-24;
        gap: $size-32;
      }

      .image-wrapper {
        position: relative;

        &+p {
          max-width: $size-181;
        }

        &:hover {
          .remove-btn {
            display: block;
          }
        }

        .remove-btn {
          display: none;
          background: transparent;
          padding: 0;
          position: absolute;
          top: 0;
          right: 0;

          &+.MuiTooltip-popper {
            .MuiTooltip-tooltip {
              background-color: $tooltip-bg-color;
              font-size: $size-12;
              display: flex;
              justify-content: center;
              align-items: center;
              width: $size-62;
              height: $size-35;

              .MuiTooltip-arrow {
                color: $tooltip-bg-color;
              }
            }
          }
        }
      }

      .update-avatar {
        height: $size-73;
        padding-top: $size-8;
        margin-left: $size-10;

        .file-input-container {
          cursor: pointer;
          margin-bottom: $size-4;
          color: $dark-color;

          .click-to-upload {
            display: flex;
            gap: $size-3;

            p:first-child {
              text-decoration-line: underline;
            }
          }
        }

        .MuiTooltip-popper {
          display: none;
        }

        .change-colour-btn {
          background-color: transparent;

          p {
            color: $dark-color;
            text-decoration-line: underline;
          }

          &.disabled {
            p {
              color: $dark-color-opacity-30;
              cursor: default;
            }

            &~.MuiTooltip-popper {
              display: block;
            }
          }
        }

        .MuiTooltip-tooltip {
          width: $size-193;
          padding: $size-13 $size-12;
        }
      }

      .picture-editor {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $size-10;

        .range-input {
          width: $size-300;
        }
      }

      .colours {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: $size-7;

        .colour {
          width: $size-31;
          height: $size-31;
          border-radius: $size-6;
          cursor: pointer;

          &.active {
            border: $size-2 solid $input-focus-color;
          }
        }
      }
    }

    .my-details-seperator {
      border-bottom: $size-1 solid $seperator-border-color;
      margin-bottom: $size-31;
    }

    .profile-radio-group {
      display: flex;
      gap: $size-24;

      .radio-group-column {
        display: flex;
        flex-direction: column;
        gap: $size-18;

        p {
          color: $dark-color;
        }

        .MuiFormGroup-root {
          gap: $size-8;

          .MuiFormControlLabel-label {
            font-family: $font;
            font-size: $size-14;
            line-height: $size-18;
          }
        }
      }
    }

    .save-button-container {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: auto;
        width: 100%;
        font-size: $size-15;

        @media (min-width: $mobile-breakpoint-width) {
          width: auto;
          font-size: $size-14;
        }
      }
    }

    @media (max-width: $mobile-breakpoint-width) {
      .unsaved-dialog {
        .flex-column--gap-16 {
          gap: $size-16;

          .column-half {
            max-width: 47%;
          }
        }
      }
    }
  }

  .my-details-right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .profile-page-image {
      max-width: $size-346;
    }
  }
}