@import '../../../../styles/Reset';

.section-file-format {
  .section-content {
    padding: $size-16 $size-22;
    display: flex;
    flex-direction: column;
    gap: $size-32;

    &-input {
      display: flex;
      align-items: center;
      gap: $size-12;
      color: $light-color;

      .input--wrap {
        max-width: $size-200;
      }
    }
  }
}