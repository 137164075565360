@import "../../../../styles/Reset";

.order-table-content {
  .content-rateRow {
    background-color: $table-chip-bg-color;
    padding: $size-8 $size-33;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dark-color;

    .content-rateRow-text {
      display: flex;
      gap: $size-5;

      a {
        font-size: $size-16;
        line-height: $size-18;
        color: $dark-color;
      }
    }

    .rate {
      display: flex;
      align-items: center;
      gap: $size-5;

      .rate-stars {
        display: flex;
        align-items: center;

        .hovered {
          path {
            fill: $orange-500-color;
            stroke: $orange-500-color;
          }

          &.last-child {
            path {
              fill: $orange-700-color;
              stroke: $orange-700-color;
            }
          }

          &.last-child~.fill {
            path {
              fill: transparent;
              stroke: $light-color;
            }
          }
        }

        .rate-star {
          background-color: transparent;
          width: $size-19;
          height: $size-19;
          display: flex;
          align-items: center;
          justify-content: center;

          &.disabled {
            cursor: default;

            &:hover {
              path {
                fill: transparent;
                stroke: $light-color;
              }
            }

            &.fill:hover {
              path {
                fill: $orange-500-color;
                stroke: $orange-500-color;
              }
            }
          }

          &.fill {
            path {
              fill: $orange-500-color;
              stroke: $orange-500-color;
            }
          }
        }
      }

      .rate-comment {
        display: flex;
        align-items: center;
        margin-bottom: (-$size-4);

        svg path {
          fill: $light-color;
        }
      }
    }
  }
}