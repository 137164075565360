@import "../../../../styles/Reset";

.order-table-content {
  .imagesDetail-confirm {
    margin-top: (-$size-1);
    width: 100%;
    max-width: $size-411;
    padding: $size-16 $size-10 $size-14;
    border: $size-1 solid $table-row-border-color;
    min-height: $size-160;
    height: fit-content;

    .imagesDetail-confirm-title {
      color: $dark-color;
      text-align: center;
      margin-bottom: $size-4;

      b {
        font-size: $size-20;
      }
    }

    .imagesDetail-confirm-text {
      margin-bottom: $size-14;
      color: $light-color;
      text-align: center;
    }

    .imagesDetail-confirm-buttons {
      display: flex;
      justify-content: center;
      gap: $size-13;

      button {
        width: $size-137;
      }

      #imagesDetail-confirm-btn {
        background-color: $success-500-color;

        &:hover {
          background-color: $success-600-color;
        }
      }
    }

    .testImage-decline-modal {
      .testImage-decline-modal-title {
        text-align: center;
        margin-bottom: $size-20;
      }

      .testImage-decline-modal-message {
        textarea {
          width: 100%;
          border: none;
          background-color: $table-gray-color;
          resize: none;
          border-radius: $size-4;
          padding: $size-10 $size-12;
          margin-bottom: $size-20;
        }
      }

      .testImage-decline-modal-buttons {
        display: flex;
        gap: $size-18;
      }
    }
  }

  .imagesDetail-processed {
    position: relative;
    top: (-$size-1);
    padding: $size-14 $size-20;
    border: $size-1 solid $table-row-border-color;
    height: $size-160;
    max-height: $size-160;
    width: 100%;
    max-width: $size-411;
    display: flex;
    flex-direction: column;
    align-items: center;

    .imagesDetail-processed-icon {
      min-width: $size-34;
      min-height: $size-34;
      border-radius: 50%;
      background-color: $success-300-color;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto $size-14;

      svg {
        width: $size-20;
        height: $size-20;
      }
    }
  }

  .imagesDetail-processed-title {
    color: $dark-color;
    text-align: center;
    max-width: $size-200;
    margin-bottom: $size-6;
  }

  .imagesDetail-processed-text {
    color: $light-color;
    text-align: center;
  }
}