@import '../../../styles/Reset';

.sepa-info-modal {
  .modal {
    padding: $size-27 $size-40 $size-30;
  }

  .sepa-info-modal-content {
    height: $size-563;

    .mandatetext {
      font-size: $size-14;
  
      p {
        margin-bottom: $size-16;
      }
  
      dl {
        overflow: auto;
      }
  
      dt {
        float: left;
        font-family: $font-semibold;
        clear: left;
        width: $size-270;
      }
  
      dt+dd {
        float: left;
        clear: none;
        margin-left: 0;
      }
    }
  }
}