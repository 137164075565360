@import '../../../styles/Reset';

$border-color: #eaecf0;

@mixin flex-items-center {
  display: flex;
  align-items: center;
}

.ftp-page {
  padding-top: $size-36;

  .top-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: $size-24;
    margin-bottom: $size-36;

    .column {
      flex: 1;
      border: $size-1 solid $border-color;
      border-radius: $size-8;
      padding: $size-24 $size-24 $size-32;

      .column-title-container {
        margin-bottom: $size-20;

        p {
          color: $dark-color;
        }
      }

      .column-subtitle-container {
        margin-bottom: $size-20;
      }

      .column-icon-container {
        @include flex-items-center();
        gap: $size-20;

        img {
          width: $size-100;
        }
      }

      a {
        p {
          border-bottom: $size-1 solid;
          display: inline;
          line-height: $size-14;
        }
      }

      a:hover {
        text-decoration: none;
      }

      .connection-info-table {

        li {
          display: flex;

          &:not(:last-child) {
            margin-bottom: $size-16;
          }

          .info-table-row-title {
            min-width: $size-105;
          }

          .info-table-row-content {
            @include flex-items-center();
          }

        }

        button {
          background-color: transparent;
          box-shadow: none;
          width: auto;
          max-height: $size-24;
          @include flex-items-center();
        }
      }
    }
  }

  .bottom-section {
    .tableTopInfo {
      margin-bottom: $size-36;

      .tableTopInfo-top {
        h2 {
          font-size: $size-16 !important;
          line-height: $size-20 !important;
        }
      }
    }

    .no-data-container {
      padding-top: $size-186;
      text-align: center;
      color: $dark-color;
    }
  }
}