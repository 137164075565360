@import '../../../styles/Reset';

.password-section {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  max-width: $size-638;

  @media (min-width: $mobile-breakpoint-width) {
    padding-left: $size-16;
  }

  .page-title {
    @media (max-width: $tablet-breakpoint-width) {
      display: none;
    }
  }

  .page-title {
    margin-bottom: $size-36;

    .tableTopInfo {
      padding: 0;
    }
  }

  .form-control {
    @media (max-width: $tablet-breakpoint-width) {
      .input__label {
        font-size: $size-13;
        line-height: $size-16;
      }

      .input {
        font-size: $size-13;
        line-height: $size-16;
      }
    }

    &:not(:nth-last-child(2)) {
      margin-bottom: $size-24;

      @media (max-width: $mobile-breakpoint-width) {
        margin-bottom: $size-21;
      }
    }

    &:nth-last-child(2) {
      margin-bottom: $size-20;

      @media (max-width: $mobile-breakpoint-width) {
        margin-bottom: $size-26;
      }
    }

    .input__label,
    .input {
      @media (max-width: $mobile-breakpoint-width) {
        font-size: $size-15;
      }
    }

    .input {
      @media (max-width: $mobile-breakpoint-width) {
        height: $size-38;
      }
    }

    .input--wrap {
      width: 100%;
    }
  }

  .button-container {
    margin-left: auto;

    @media (max-width: $mobile-breakpoint-width) {
      max-width: 100%;
      width: 100%;

      button {
        width: 100% !important;
        font-size: $size-15;
        height: $size-38;
      }
    }
  }
}