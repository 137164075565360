@import "../../../styles/Reset";

.table-content {
  padding: $size-9 $size-26 $size-9 $size-31;

  .content-title {
    display: flex;
    align-items: center;
    gap: $size-3;
    color: $dark-color;
    margin: $size-25 0;

    svg {
      width: $size-20;
      height: $size-20;
    }
  }
}

.table-animation {
  padding: $size-24 0;

  .loading-dots {
    display: flex;
    justify-content: center;
  }
}