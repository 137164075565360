@import '../../../styles/Reset';

.newRegister-box {
  text-align: center;
  width: $size-352;
  margin: $size-190 auto;
  padding: $size-20;

  @media (min-width: $mobile-breakpoint-width) {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    &.full-height {
      height: calc(100vh - #{$size-72} - #{$size-50} - #{$size-46});
    }
  }

  .newRegister-box-icon {
    display: flex;
    justify-content: center;
    margin-bottom: $size-20;  

    svg {
      width: $size-40;
      height: $size-46;
    }

  }

  .newRegister-box-title {
    color: $dark-color;
    margin-bottom: $size-8;
  }

  .newRegister-box-text {
    margin-bottom: $size-32;
  }

  .newRegister-box-buttons {
    display: flex;
    gap: $size-16;
    
    button {
      font-size: $size-13;
      line-height: $size-16;
    }
  }

}