@import '../../../../styles/Reset';

.info-modal {
  .info-modal-icon {
    background-color: transparent;
    display: flex;
    padding-bottom: $size-1;

    svg {
      width: $size-18;
      height: $size-18;
    }
  }

  .modal--wrap {
    z-index: $modal-z-index;
    position: fixed;
    background-color: $modal-bg-color;

    .info-modal-title h2 {
      text-align: center;
      color: $switch-active-color;
      margin-bottom: $size-16;
    }

    .info-modal-text {

      p {
        margin-bottom: $size-10;
        color: $dark-color;
      }

      h3,
      h4 {
        color: $modal-subtitle-color;
        margin-bottom: $size-6;
        font-size: $size-16;
      }

      img {
        max-width: 100%;
        height: auto;
        margin: $size-10 0;
      }

    }
  }
}