@import '../../../styles/Reset';

$admin-chip-bg-color: #231975;
$add-template-button-bg-color: #F9FAFB;
$tooltip-bg-color: #101828;

.user-management-section {
  .tableTopInfo {
    padding: 0 0 0 $size-16;
  }

  .add-button-container {
    display: flex;
    justify-content: flex-end;

    .button {
      width: auto;
      white-space: nowrap;
      border-radius: $size-7;

      .add-button-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: $size-8;

        p {
          font-size: $size-14;
          color: $dark-color;
          font-family: $font-semibold;
        }
      }
    }
  }

  .table-wrapper {
    .table-row {
      &:last-child {
        .more-actions-content {
          top: (-$size-20);
        }
      }
    }

    .user-info-content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: $size-12;

      .admin-user-chip {
        background-color: $admin-chip-bg-color;
        border-radius: $size-4;
        line-height: $size-18;
        padding: $size-2 $size-8;
        height: $size-22;

        span {
          padding: 0;
          font-family: $font-medium;
          color: $white-color;
        }
      }
    }

    .add-template-button {
      width: fit-content;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        background-color: $add-template-button-bg-color !important;
        box-shadow: none;
      }
    }

    .template-chips-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: $size-4;

      .template-chip {
        border: $size-2 solid $dark-color;
        height: $size-22;

        span {
          color: $light-color;
          font-size: $size-12;
          font-family: $font-medium;
          padding-left: $size-8;
          padding-right: $size-8;
        }
      }
    }

    .table-row-cell-buttons {
      justify-content: flex-end;

      .table-row-cell-buttons-warning {
        background-color: transparent;
        display: flex;
        align-items: center;
        margin-top: $size-2;
      }

      .more-actions {
        position: relative;
        display: flex;
        justify-content: flex-end;
        padding-top: $size-7;

        &-icon {
          background-color: transparent;
        }

        &-content {
          position: absolute;
          right: $size-42;
          top: 50%;
          transform: translateY(-50%);
          background-color: $white-color;
          padding: $size-13 $size-13 $size-7;
          border-radius: $size-8;
          border: $size-1 solid $table-row-border-color;
          box-shadow: 0 $size-4 $size-8 rgba(0, 0, 0, 0.05);

          &-row {
            background-color: transparent;
            display: flex;
            align-items: center;
            gap: $size-10;
            white-space: nowrap;
            color: $dark-color;
            padding: $size-6 $size-12;
            border-radius: $size-8;
            width: 100%;
            height: $size-36;

            svg {
              width: $size-22;
              height: $size-22;
            }

            &:hover {
              background-color: $box-menu-item-bg-hover-color;
            }
          }
        }
      }
    }
  }

  .warning-tooltip {
    +.MuiTooltip-popper {
      .MuiTooltip-tooltip {
        width: $size-193;
        height: auto;

        button {
          font-size: $size-12;
          color: $white-color;
          background: transparent;
          font-family: $font-medium;
          text-decoration: underline;
        }
      }
    }
  }
}

.user-management-section-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: $size-92;
  margin: 0 auto;
  max-width: $size-330;

  .doopic-logo {
    width: $size-32;
    margin-bottom: $size-16;
  }

  p {
    color: $dark-color;
  }
}