@import "../../../styles/Reset";

.table-content {
  padding: $size-9 $size-26 $size-9 $size-31;

  .table-content-tabs {
    display: flex;
    gap: $size-10;

    .table-content-tab {
      display: flex;
      align-items: center;
      gap: $size-4;
      color: $dark-color;
      margin: $size-10 0 $size-20 0;
      padding: $size-8 $size-16;
      background-color: transparent;
      border-radius: $size-4;

      svg {
        width: $size-20;
        height: $size-20;
      }

      &.active {
        background-color: $light-color;
        color: $white-color;

        svg path {
          fill: $white-color;
        }
      }
    }
  }

  &-settings {
    display: grid;
    gap: $size-20;
    margin-bottom: $size-10;

    .content-title {
      margin: 0;
    }

    .detail-row {
      &-title {
        color: $dark-color;
        margin-bottom: $size-15;
        display: flex;
        align-items: center;
        gap: $size-5;

        svg {
          width: $size-16;
          height: $size-16;
        }

        .info-tooltip {
          &~.MuiTooltip-popper {
            max-width: $size-240;
          }
        }
      }

      &-content {
        display: flex;
        flex-wrap: wrap;
        gap: $size-15 $size-50;

        &-item {
          display: flex;
          align-items: center;
          color: $dark-color;

          &.item-text {
            gap: $size-5;

            .item-text-badge {
              background-color: $gray-text-bg-color;
              padding: 0 $size-6;
            }
          }

          &.item-checkbox {
            gap: $size-10;

            .MuiCheckbox-root {
              padding: 0;
            }

            &.disabled p {
              opacity: 0.3;
            }
          }

          &.item-text,
          &.item-checkbox {
            .info-tooltip {
              width: $size-16;
              height: $size-16;

              &~.MuiTooltip-popper {
                max-width: $size-240;
              }
            }
          }

          &.user-profile {
            gap: $size-10;
            padding: $size-8;
          }
        }
      }
    }

    .separate-line-wrapper {
      padding: 0 $size-40;

      .separate-line {
        width: 100%;
        height: $size-1;
        margin: $size-10 0;
        background-color: $table-row-border-color;
      }
    }
  }

  .content-bottom {
    display: flex;
    justify-content: space-between;
    margin: $size-16 0 $size-32;
    color: $light-color;
    font-size: $size-14;

    &-viewText {
      display: flex;
      align-items: center;
      gap: $size-7;
    }
  }
}

.table-animation {
  padding: $size-24 0;

  .loading-dots {
    display: flex;
    justify-content: center;
  }
}