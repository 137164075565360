@import '../../styles/Reset';

$textarea-rows: 5;

$textarea-s-width: $size-360;
$textarea-s-line-height: $size-24;
$textarea-s-height: $textarea-rows * 1.02 * $textarea-s-line-height;
$textarea-s-padding: $size-12 $size-14;
$textarea-s-font-size: $size-14;
$textarea-s-label-margin: $size-6;
$textarea-s-error-margin: $size-8;

.textarea {
  resize: none;
  background-color: $white-color;
  color: $textarea-color;
  width: 100%;
  height: $textarea-s-height;
  max-height: $textarea-s-height;
  line-height: $textarea-s-line-height;
  padding: $textarea-s-padding;
  font-size: $textarea-s-font-size;
  border: $size-1 solid $textarea-border-color;
  border-radius: $size-4;
  outline: none;
}

.textarea::placeholder {
  color: $textarea-placeholder-color;
}

.textarea:focus {
  box-shadow: 0 0 0 $size-1 $textarea-focus-color;
  border: $size-1 solid $textarea-focus-color;
}

.textarea__label, .textarea__error {
  text-align: left;
  white-space: nowrap;
  font-size: $textarea-s-font-size;
  height: $size-20;
  line-height: $size-20;
}

.textarea__label {
  color: $textarea-label-color;
  font-family: $font-medium;
  margin-bottom: $textarea-s-label-margin;
}

.textarea__error {
  color: $textarea-error-color;
  margin-top: $textarea-s-error-margin;
  margin-bottom: 0;
}

.textarea--error, .textarea--error:focus {
  box-shadow: 0 0 0 $size-1 $textarea-error-color;
  border: $size-1 solid $textarea-error-color;
}

.textarea--wrap, .textarea--s--wrap {
  width: $textarea-s-width;
  max-width: 100%;
}

.not-logged-page {
  .textarea__label {
    color: $not-logged-textarea-label-color;
  }
}
