@import '../../../styles/Reset';

.public-gallery-settings {
  &-section.redos-approval {
    .switch-columns {
      display: flex;
      gap: $size-32;

      .switch-column,
      .check-column {
        display: flex;
        width: 33%;
        gap: $size-8;

        &-title {
          width: $size-100;

          p {
            font-size: $size-16;
            color: $dark-color;
            font-family: $font-semibold;
          }
        }

        .switch-group {
          display: flex;
          flex-direction: column;
          gap: $size-8;

          .switchs-row {
            display: flex;
            gap: $size-20;
          }

          label {
            display: flex;
            align-items: center;

            p {
              font-size: $size-14;
              color: $dark-color;
            }
          }

          &.switch-group-columns {
            display: flex;
            gap: $size-20;
          }
        }

        .inputs-group {
          .check-row {
            margin-bottom: $size-12;

            p {
              font-size: $size-14;
              margin-bottom: $size-12;
              color: $dark-color;
              font-family: $font-semibold;
            }

            .MuiFormControlLabel-root {
              margin-right: 0;
            }
          }

          .input-row {
            display: flex;
            align-items: center;
            gap: $size-12;
            margin-bottom: $size-32;

            p {
              font-size: $size-14;
            }

            .input--wrap {
              width: $size-40;
            }
          }
        }
      }
    }
  }

}