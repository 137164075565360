@import '../../../styles/Reset';

.tableTopInfo {
  max-width: $size-525;
  padding: $size-10 0;

  &.mobile {
    .tableTopInfo-text {
      max-width: $size-300;
    }

    .tableTopInfo-top {
      margin-bottom: $size-2;

      .tableTopInfo-top-chip {
        padding: $size-1 $size-4;
      }
    }
  }

  .tableTopInfo-top {
    display: flex;
    align-items: center;
    margin-bottom: $size-4;

    h2 {
      color: $dark-color;
      margin-right: $size-8;
    }

    .tableTopInfo-top-chip {
      background-color: $table-chip-bg-color;
      color: $input-focus-color;
      padding: $size-2 $size-8;
      border-radius: $size-16;
      white-space: nowrap;
    }
  }

  .tableTopInfo-templates-list {
    font-family: $font-medium;
    border-bottom: $size-1 solid $light-color;
  }
}