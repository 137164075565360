@import '../../../../styles/Reset';

.orderTable {
  .orderTable-status {
    width: $size-34;
    height: $size-34;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.success-300 {
      background-color: $success-300-color;
    }

    &.success-400 {
      background-color: $success-400-color;
    }

    &.success-500 {
      background-color: $success-500-color;
    }

    &.orange-500 {
      background-color: $orange-500-color;
    }

    &.gray {
      background-color: $status-gray-color;
    }
  }

}

#status-tooltip {
  .MuiTooltip-tooltip {
    background-color: transparent !important;

    .status-hover {
      background-color: $table-status-hover-bg-color;
      border-radius: $size-8;
      padding: $size-21 $size-28 $size-19 $size-28;
      display: grid;
      gap: $size-18;

      .status-hover-row {
        display: flex;
        align-items: center;
        gap: $size-14;

        .row-iconWrapper {
          width: $size-28;
          height: $size-28;
          background-color: $success-500-color;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          svg {
            max-width: $size-12;
            max-height: $size-12;
          }
        }

        &.active {
          .row-iconWrapper {
            background-color: $white-color;
            border: $size-3 solid $success-500-color;

            svg {
              path {
                fill: $success-500-color;
              }
            }
          }

          &~.status-hover-row .row-iconWrapper {
            background-color: $white-color !important;

            &::before {
              background-color: $white-color !important;
            }

            svg {
              path {
                fill: $table-status-hover-icon-color;
              }
            }
          }
        }

        &:nth-child(n+2) {
          .row-iconWrapper {
            &:before {
              content: '';
              position: absolute;
              bottom: $size-28;
              left: 50%;
              transform: translateX(-50%);
              width: $size-2;
              height: $size-20;
              background-color: $success-500-color;
            }
          }
        }
      }
    }
  }
}