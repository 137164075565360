@import "../../../../../styles/Reset";

.rate-modal {
  .modal {
    box-shadow: 0 0 $size-37 (-$size-4) rgba(0, 0, 0, 0.20);
  }

  .rate-modal-stars {
    display: flex;
    justify-content: center;
    margin-bottom: $size-20;

    svg {
      width: $size-25;
      height: $size-25;
    }
  }

  .rate-modal-title {
    text-align: center;
    margin-bottom: $size-8;
    color: $dark-color;
  }

  .rate-modal-text {
    text-align: center;
    color: $light-color;
  }

  .rate-modal-options {
    margin: 0 auto $size-20 auto;
    gap: $size-10 $size-20;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: $size-250;

    button {
      border: $size-1 solid $gray-700-color;
      padding: $size-2 $size-8;
      background-color: $white-color;
      color: $gray-700-color;
      border-radius: $size-16;
      height: auto;

      &.active {
        border: $size-1 solid $not-logged-dark-color;
        background-color: $not-logged-dark-color;
        color: $white-color;
      }
    }
  }

  .rate-modal-message {
    margin-bottom: $size-20;

    textarea {
      border: none;
      width: 100%;
      height: $size-94;
      background-color: $modal-textarea-bg-color;
      padding: $size-9 $size-10;
      resize: none;
      border-radius: $size-4;
      color: $dark-color;

      &::placeholder {
        color: $debtBox-corrected-bg-color;
      }
    }
  }

  .rate-modal-buttons {
    display: flex;
    justify-content: center;
    gap: $size-16;
  }
}