@import '../../../../styles/Reset';

.image-search-box {
  position: absolute;
  z-index: 2;
  top: $size-48;
  left: 50%;
  transform: translateX(-50%);
  background-color: $white-color;
  border-radius: $size-8;
  box-shadow: 0 $size-4 $size-20 rgba(0, 0, 0, 0.25);
  border: $size-1 solid $table-row-border-color;
  display: flex;

  .box-options {
    width: $size-287;
    padding: $size-6;
    border-right: $size-1 solid $table-row-border-color;

    &.isSubmit {
      width: $size-240;
    }

    .box-options-title {
      position: relative;
      color: $dark-color;
      margin-bottom: $size-4;
      padding: $size-9 $size-10;
      text-align: left;
      border-bottom: $size-1 solid $table-row-border-color;

      svg {
        cursor: pointer;
        position: absolute;
        top: $size-8;
        right: $size-8;
      }
    }

    .box-options-row {

      .row-titleWrapper {
        color: $gray-700-color;
        text-align: left;
        display: flex;
        align-items: center;
        gap: $size-8;
        padding: $size-9 $size-10;
        margin-bottom: $size-6;
      }

      .inputWrapper {
        padding: 0 $size-10;
        margin-bottom: $size-6;
      }

    }

    .box-options-checkRow {
      padding: $size-9 $size-10;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $size-6;

      .MuiCheckbox-root {
        padding: $size-2;
      }

      .checkRow-titleWrapper {
        display: flex;
        align-items: center;
        gap: $size-8;
        color: $gray-700-color;
      }

    }

    .box-options-button {
      display: flex;
      justify-content: center;
      margin-bottom: $size-10;
    }

    .box-warning {
      text-align: center;
      margin-bottom: $size-6;
    }

  }

  .box-table-loading {
    width: $size-620;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-dots {
      display: flex;
      justify-content: center;
    }
  }

  .box-table {
    width: $size-620;
    border-left: none;
    padding: $size-12 $size-40 0 $size-10;
    position: relative;

    .table-container.table-scroll {
      max-height: $size-420;
    }

    table {
      .table-head-cell {
        white-space: nowrap;
        padding: $size-8 $size-17;
      }

      .table-row-cell {
        padding: $size-14 $size-17;
      }

      .table-head-cell,
      .table-row-cell {

        span,
        a {
          font-size: $size-10 !important;
          line-height: $size-14 !important;
        }
      }

      .order-name, .order-template-name {
        font-size: $size-10 !important;
        line-height: $size-14 !important;
      }
    }

    .box-table-bottom {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, $white-color 100%);
      height: $size-80;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

}