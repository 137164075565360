@import '../../styles/Reset';

$slide-width: $size-447;
$slide-height: $size-322;
$slide-shadow-width: $size-4;
$slide-margin: $size-15;

@mixin login-slideshow {
  width: calc(#{$slide-width} + 2 * #{$slide-shadow-width} + 2 *  #{$slide-margin});
  max-width: 48vw;
}

.login-page {
  display: flex;
  height: 100vh;
  min-height: $size-800;

  .login-form--wrap, .login-ad--wrap {
    position: relative;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .login-form--wrap {
    display: flex;
    flex-direction: column;

    .login--logo {
      height: $size-41;
      margin: 0 auto;
    }
  }

  .login-form--wrap--form {
    width: $size-360;
    margin: 0 auto;
  }

  .login-ad--wrap {
    display: none;
    background: #5268b7 url("../../svg/login_ad_bg.svg") center center/cover no-repeat;
    color: $white-color;

    .login-ad__image--wrap, .login-ad__image {
      width: $slide-width;
      height: $slide-height;
      border-radius: $size-5;
    }

    .login-ad__image--wrap {
      background-color: #231975;
      margin: $slide-shadow-width $slide-margin;
    }

    .login-ad__subtitle {
      max-width: $size-400;
      margin: $size-8 auto 0;
    }
  }

  .login-form__line {
    width: $size-360;
    
    &.recapthca p,
    &.recapthca a {
      color: $light-color !important;
    }
  }

  .login-ad-line--wrap {
    @include login-slideshow;
  }

  .login-ad-slide {
    @include login-slideshow;
  }

  $login-slider-indicator-color: $not-logged-dark-color;
  $login-slider-indicator-active-color: #FFFFFF;

  .indicator {
    background-color: $login-slider-indicator-color;
    color: $login-slider-indicator-color;
  }

  .indicator.active {
    background-color: $login-slider-indicator-active-color;
    color: $login-slider-indicator-active-color;
  }

  .google-btn {
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $size-12;
    }
  }

  @media (min-width: $login-ad-breakpoint-width) {
    .login-form--wrap, .login-ad--wrap {
      width: 50%;
    }

    .login-form--wrap--simple {
      width: 100%;
    }

    .login-ad--wrap {
      display: inherit;
    }
  }
}