@import '../../../styles/Reset';

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

$card-info-color: #344054;

.manage-payments-container {
  height: $size-563;
  display: flex;
  flex-direction: column;

  .manage-payments-title {
    margin-bottom: $size-12;
  }

  .row {
    @include flex-row;
    margin-bottom: $size-24;

    .add-methods-button {
      background-color: transparent;
      margin-left: auto;
    }

    .add-methods-button p {
      border-bottom: $size-1 solid;
    }
  }

  .after-payment-status {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: $size-490;
    margin: 0 auto;

    .doopic-icon {
      width: $size-76;
      margin-bottom: $size-38;
    }

    .payment-error-icon {
      margin-bottom: $size-38;
    }

    .title {
      margin-bottom: $size-11;
    }

    .description {
      margin-bottom: $size-40;
    }

    .try-again {
      .button {
        height: $size-33;
        font-size: $size-15;
        line-height: $size-18;
        padding-left: $size-18;
        padding-right: $size-18;
      }
    }
  }

  .form-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: $size-12;
    margin-top: auto;
    padding-bottom: $size-2;

    .button {
      line-height: $size-14;
    }

    .use-different-payment-btn {
      background-color: transparent;
      border: none;
      margin-right: auto;
      color: $card-info-color;
      white-space: nowrap;

      p {
        border-bottom: $size-1 solid;
      }
    }
  }
}