@import "../../../../styles/Reset";

.order-table-content {
  .content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: $table-chip-bg-color;
    padding: $size-7 $size-44;
    color: $light-color;
    font-size: $size-14;
    line-height: $size-20;
    margin-bottom: $size-20;

    .content-top-column {
      display: flex;
      align-items: center;
      gap: $size-8;
    }

    .btn-prioritise-container {
      min-width: $size-172;

      .btn-prioritise {
        border: $size-1 solid $light-color;
        border-radius: $size-4;
        padding: $size-2 $size-9;
        background-color: transparent;
        color: $light-color;
        transition: 0.2s;
        text-transform: uppercase;
        margin-left: auto;

        &:hover {
          opacity: 0.8;
        }

        &.unPrioritise {
          background-color: $white-color;
          border: $size-1 solid transparent;
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}