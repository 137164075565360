@import '../../styles/Reset';

.mailing-unsubscribe-page {
  display: flex;
  height: 100vh;
  min-height: $size-500;

  .mailing-unsubscribe-page--wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .screen-center-top {
    max-width: $size-352;
  }

  .h6 {
    color: $dark-color;
  }

  p {
    color: $light-color;
  }

  .doopic-logo {
    width: $size-40;
  }
}