@import '../../../../styles/Reset';

.profile-form {
  margin-bottom: $size-18;

  @media (min-width: $mobile-breakpoint-width) {
    margin-bottom: $size-41;
  }

  .title-firstname-lastname,
  .email-phonenumber {
    display: flex;
    gap: $size-21;
  }

  .email-phonenumber {
    flex-direction: column;

    @media (min-width: $mobile-breakpoint-width) {
      flex-direction: row;
    }

    .input--wrap {
      @media (max-width: $mobile-breakpoint-width) {
        width: 100%;
      }
    }
  }

  .title-firstname-lastname {
    flex-direction: column;
    margin-bottom: $size-22;

    @media (min-width: $mobile-breakpoint-width) {
      flex-direction: row;
    }

    .input--wrap {
      @media (max-width: $mobile-breakpoint-width) {
        width: 100%;
      }
    }

    .title-select-container {
      width: $size-109;

      @media (min-width: $mobile-breakpoint-width) {
        width: $size-96;
        max-width: $size-96;
        min-width: $size-96;
      }

      .MuiSelect-select {
        font-size: $size-15;
        padding-top: $size-25;

        @media (min-width: $mobile-breakpoint-width) {
          font-size: $size-14;
          padding-top: $size-22;
        }
      }
    }
  }

  @media (max-width: $mobile-breakpoint-width) {

    .input__label,
    .input {
      font-size: $size-15;
    }
  }
}