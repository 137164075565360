@import '../../../styles/Reset';

.public-gallery-settings {
  &-section.views-features {
    .switch-columns {
      display: flex;
      gap: $size-32;

      .switch-column {
        display: flex;
        width: 33%;
        gap: $size-8;

        &-title {
          width: $size-100;

          p {
            font-size: $size-16;
            color: $dark-color;
            font-family: $font-semibold;
          }
        }

        .switch-group {
          display: flex;
          flex-direction: column;
          gap: $size-4;

          label {
            display: flex;
            align-items: center;

            p {
              font-size: $size-14;
              color: $dark-color;

            }
          }
        }
      }
    }
  }

}