@import '../../styles/Reset';

.lang-menu--wrap {
  z-index: $lang-menu--z-index;

  position: absolute;
  top: $size-27;
  right: 0;

  .lang-menu {
    position: relative;
    text-align: right;

    height: $size-40;

    .lang-menu--icon, .lang-menu--icon__not-logged {
      position: absolute;
      top: 0;
      right: 0;

      cursor: pointer;
      width: $header-icon-height;
      height: $header-icon-height;

      background: url("../../svg/languages.svg") center center/cover no-repeat;
    }

    .lang-menu--icon__not-logged {
      background: url("../../svg/languages_blue.svg") center center/cover no-repeat;
    }

    .lang-menu--icon__white {
      background: url("../../svg/languages_blue.svg") center center/cover no-repeat;
    }
  }

  .lang-menu__menu {
    padding: $size-13 $size-12;
    border: $size-1 solid #EAECF0;
    background-color: $white-color;
    box-shadow: 0 $size-12 $size-16 (-$size-4) rgba(16, 24, 40, .08), 0 $size-4 $size-6 (-$size-2) rgba(16, 24, 40, .03);
    border-radius: $size-8;
  }

  .lang-menu__menu-item {
    position: relative;
    cursor: pointer;
    height: $size-36;
    border-radius: $size-8;
    padding-left: $size-47;
    padding-right: $size-31;
    margin-bottom: $size-2;
  }

  .lang-menu__menu-item p {
    color: $dark-color !important;
    font-family: $font-medium;
  }

  .lang-menu__menu-item:hover {
    background-color: $hover-bg-color;
  }

  .lang-menu__menu-item--active {
    background-color: $hover-bg-color;
  }

  .lang-menu__menu-item p {
    line-height: $size-36;
  }

  .lang-menu__menu-item svg {
    position: absolute;
    left: $size-11;
    top: $size-8;
  }

  @media (min-width: $register-ad-breakpoint-width) {
    .lang-menu {
      .lang-menu--icon__white {
        background: url("../../svg/languages_white.svg") center center/cover no-repeat;
      }
    }
  }
}

.not-logged-page {
  .lang-menu--wrap {
    top: $size-30;
    right: $size-32;

    .lang-menu {
      height: $size-22;
    }
  }
}