@import '../../../styles/Reset';

.doo-password-strength {
  > div {
    margin: $size-8 0 0 !important;
  }

  > div > div {
    height: $size-4 !important;
    border-radius: $size-4 !important;
  }

  .doo-score-word {
    font-size: $size-14 !important;
    line-height: $size-18 !important;
    font-family: $font-medium !important;
    margin: $size-3 0 0 !important;
    color: $dark-color !important;
  }
}

.not-logged-page {
  .doo-score-word {
    color: $not-logged-input-label-color !important;
  }
}