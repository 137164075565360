@import '../../../../styles/Reset';

.general-settings {
  border: $size-1 solid $input-border-color;
  border-radius: $size-8;

  .settings-section {
    width: 100%;
    padding: $size-28;

    &:not(:last-child) {
      border-bottom: $size-1 solid $input-border-color;
    }

    .settings-section-columns {
      display: flex;
      width: 100%;
      gap: $size-24;
      margin: $size-12 0 $size-36;

      .settings-section-column {
        width: 25%;

        .column-title {
          margin-bottom: $size-6;
        }
      }
    }

    .settings-section-inputs {
      display: flex;
      align-items: center;
      gap: $size-48;
      margin-top: $size-12;

      label {
        display: flex;
        align-items: center;
      }
    }

    .settings-section-bottom {
      display: flex;
      gap: $size-36;

      .section-bottom-left {
        width: 40%;

        label {
          display: flex;
          align-items: center;
          margin-bottom: $size-6;
          width: inherit;
        }
      }

      .section-bottom-right {
        width: 60%;

        .right-inputs {
          display: flex;
          align-items: center;
          gap: $size-24;
          margin-top: $size-12;
        }
      }
    }

    .count-input {
      display: flex;
      align-items: center;
      gap: $size-12;

      .input--wrap {
        width: $size-72;
      }
    }

    &.two-column {
      display: flex;
      gap: $size-24;

      .section-half {
        width: 50%;

        &-content {
          display: flex;
          gap: $size-48;
          margin-top: $size-12;

          .settings-section-column {
            .column-title {
              margin-bottom: $size-6;
            }

            .column-input {
              display: flex;
              align-items: center;
              gap: $size-6;

              .input--wrap {
                width: $size-72;
              }
            }

            .column-select {
              width: $size-120;
            }
          }
        }
      }
    }
  }
}