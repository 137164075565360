@import '../../styles/Reset';

.image-search {
  .image-search-actions {
    margin-top: $size-52;

    .actions-form {
      display: flex;
      align-items: flex-end;
      margin-bottom: $size-18;

      .actions-form-inputs {
        display: flex;
        gap: $size-21;

        .input--wrap {
          max-width: $size-300;


          &:last-child {
            max-width: $size-132;
          }

          .input__label {
            color: $dark-color;
          }
        }
      }

      .actions-form-right {
        display: flex;
        align-items: center;
        gap: $size-25;

        .actions-form-checkboxs {
          display: flex;

          label {
            display: flex;
            align-items: center;

            p {
              color: $light-color;
              font-family: $font;
            }
          }
        }
      }
    }

    .actions-warning {
      color: $light-color;
    }
  }

  .image-search-table {
    margin-top: $size-40;

    td {
      span,
      a {
        font-size: $size-14;
        color: $dark-color;
      }
    }

    .order-template-name {
      font-size: $size-12;
      color: #808185;
    }
  }
}