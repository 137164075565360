@import '../../../../styles/Reset';

.section-default-settings {

  .column-brand {
    display: flex;
    align-items: center;
    gap: $size-15;
    cursor: pointer;
    margin-bottom: $size-20;

    img {
      max-height: $size-18;
    }
  }

  .MuiFormGroup-root {
    gap: $size-15;

    .MuiFormControlLabel-label {
      font-family: 'roobert';
      display: flex;
      align-items: flex-start;
      gap: $size-8;
      padding: $size-1;
    }
  }

  .section-content-info {
    display: flex;
    align-items: center;
    margin: 0 $size-22 $size-22;
    padding: $size-16;
    gap: $size-15;
    border: $size-1 solid $table-row-border-color;

    .info-modal-icon {
      svg {
        width: $size-32;
        height: $size-32;
      }
    }
  }

  .doo-label .MuiFormControlLabel-label {
    margin-right: $size-10;
  }

}