@import "../../../../styles/Reset";

.acceptPriceBox,
.acceptPriceBox-modal {
  border: $size-1 solid $table-row-border-color;
  padding: $size-14 $size-54 $size-20;
  margin-top: $size-24;
  max-width: $size-420;

  .modal {
    min-height: auto;
  }

  .acceptPriceBox-title,
  .acceptPriceBox-modal-title {
    text-align: center;
    margin: $size-9 0 $size-10;
    color: $dark-color;
  }

  .acceptPriceBox-text,
  .acceptPriceBox-modal-text {
    text-align: center;
    margin-bottom: $size-18;
    color: $light-color;
  }

  .acceptPriceBox-button {
    display: flex;
    justify-content: center;

    button {
      background-color: $success-500-color;
      padding: 0 $size-23;

      &:hover {
        background-color: $success-600-color;
      }
    }
  }

  .acceptPriceBox-modal-buttons {
    display: flex;
    gap: $size-16;
  }
}

.acceptPriceBox-modal {
  border: none;
  padding: 0;
  margin: 0;
}