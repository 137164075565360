@import '../../../../styles/Reset';

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

$border-color: #EAECF0;
$icon-border-color: #F2F4F7;
$card-info-color: #344054;
$primary-bg-color: #447AF0;
$primary-text-color: #F8F8F8;

.select-payment-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  .payment-method-item {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    height: $size-76;
    border-bottom: $size-1 solid $border-color;

    &:last-child {
      border-bottom: none;
    }

    .method-left,
    .method-right {
      @include flex-row;
      align-items: center;
      border: none;
    }

    @media (min-width: $hd-breakpoint-width) {
      .checkbox-icon {
        height: $size-20;
      }

      .info-icon {
        height: $size-21;
      }
    }

    .payment-icon-wrapper {
      margin-left: $size-16;
      width: $size-58;
      height: $size-40;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $size-6;
      border: $size-1 solid $icon-border-color;
    }

    .payment-method-name {
      margin-left: $size-30;

      p {
        color: $card-info-color;
      }
    }

    .warning-icon {
      margin-right: $size-65;
    }

    .info-icon {
      margin-right: $size-65;

      @media (min-width: $hd-breakpoint-width) {
        height: $size-21;
      }
    }

    .primary-blue {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary-bg-color;
      border-radius: $size-8;
      width: $size-109;
      height: $size-36;
      margin: 0 $size-22 0 auto;

      p {
        color: $primary-text-color;
      }
    }
  }

  &.scrollable {
    padding-right: $size-14;
    padding-bottom: $size-14;
  }
}